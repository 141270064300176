import { IProfile } from 'src/app/models/profile'

export interface AppUserState {
  profile: IProfile
  authorized: boolean
  loading: boolean
}

export enum SubscriptionStatus {
  Trial = 'trial',
  Valid = 'active',
  Expired = 'expired',
}

export enum SubscriptionPlan {
  FREE = 'free',
  START = 'start',
  PRO = 'pro',
  BUSINESS = 'business',
  TRAFFIC = 'traffic',
  DOWOW = 'dowow',
}

export const initialUserState: AppUserState = {
  profile: {
    _id: null,
    firstName: null,
    lastName: null,
    role: null,
    email: null,
    user: null,
    tosAgreed: false,
    paywallId: null,
    subscriptionPlan: SubscriptionPlan.FREE,
    subscriptionStatus: SubscriptionStatus.Expired,
    trialExpirationDate: null,
    limits: null,
    traffic: null,
    storage: null,
    features: null,
    integrations: null,
  },
  authorized: false,
  loading: true,
}
