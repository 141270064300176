export const getMenuData: any = {
  singleEvent: [
    {
      category: true,
      title: 'Workspaces',
    },
    {
      title: 'Event details',
      key: 'events-dashboard',
      icon: 'fe fe-info',
      url: '/events/id',
    },
    {
      title: 'Edit event',
      key: 'events-create',
      icon: 'fe fe-edit',
      url: '/events/id/edit',
    },
  ],
  newEvent: [
    {
      category: true,
      title: 'Workspaces',
    },
  ],
  general: [
    {
      category: true,
      title: 'Workspaces',
    },
    {
      title: 'Your Events',
      key: 'events-dashboard',
      icon: 'fe fe-layers',
      url: '/events/dashboard',
    },
    {
      title: 'Create event',
      key: 'events-create',
      icon: 'fe fe-plus',
      url: '/events/create',
    },
    {
      category: true,
      title: 'Devices',
      feature: 'devices',
    },
    {
      title: 'Your Devices',
      key: 'devices-dashboard',
      icon: 'dw-devices',
      url: '/devices/dashboard',
      feature: 'devices',
    },
    {
      category: true,
      title: 'Admin',
    },
    {
      title: 'Manage Subscription',
      key: 'app-settings',
      icon: 'far fa-usd-circle',
      url: '/settings/application',
    },
    {
      title: 'Integrations',
      key: 'app-integrations',
      icon: 'dw-socialshare',
      url: '/settings/integrations',
    },
  ],
}
