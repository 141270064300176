<aside [ngStyle]="{ 'width.px': isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
                    'height': isMobileView ? '100vh' : 'calc(100vh - 48px)'  }"
       [ngClass]="{menu: true,
                   white: menuColor === 'white',
                   gray: menuColor === 'gray',
                   dark: menuColor === 'dark',
                   unfixed: isMenuUnfixed,
                   shadow: isMenuShadow && !isMobileView}">
  <div class="menuOuter"
       [ngStyle]="{'width.px': isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth ,
                   'height': isMobileView || isMenuUnfixed ? 'calc(100% - 100px)' : 'calc(100% - 140px)'}">
    <div class="logoContainer">
      <a class="logo" href routerLink="/events/dashboard" (click)="clearCopiedPlaylistCards()">
        <img *ngIf="!isMenuCollapsed" src="assets/images/logo-text-purple.svg" alt="logo" width="auto">
        <img *ngIf="isMenuCollapsed" src="assets/images/logo-purple.svg" alt="logo" width="auto">
      </a>
    </div>
    <perfect-scrollbar>
      <ul class="navigation">
        <ng-container *ngTemplateOutlet="menuTpl; context: {$implicit: menuDataActivated, level: 0}"></ng-container>
        <ng-template #menuTpl let-menuDataActivated let-level="level">
          <ng-container *ngFor="let item of menuDataActivated">
            <ng-container *ngIf="isUserProfileLoaded && (!item.roles || item.roles.includes(role))">

              <!-- group title item -->
              <li *ngIf="item.category && isEnabled(item.feature || 'no-feature') && (!isMenuCollapsed || isMobileView)" class="menu-item-group-title menu-item-group-title__uncollapsed"> {{item.title}}</li>
              <li *ngIf="item.category && isEnabled(item.feature || 'no-feature') && isMenuCollapsed && !isMobileView" class="menu-item-group-title menu-item-group-title__collapsed">
                <mat-divider style="background: #e4e9f0;"></mat-divider>
              </li>

              <!-- disabled item -->
              <li *ngIf="item.disabled"
                  class="menu-item workspace-item"
                  [ngClass]="{'menu-item__selected': item.selected,
                              'menu-item__uncollapsed': !isMenuCollapsed || isMobileView,
                              'menu-item__collapsed': isMenuCollapsed && !isMobileView}"
                  [disabled]="true"
                  [matTooltip]="isMenuCollapsed && level === 0 ? item.title : ''"
                  matTooltipPosition="right">
                <span *ngIf="!isMenuCollapsed || isMobileView" [ngStyle]="{'padding-left.px': level * 15}">
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success" style="margin-left: 15px;">{{ item.count }} </span>
                </span>
                <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
              </li>


              <!-- main item -->
              <li *ngIf="!item.category && !item.children && isEnabled(item.feature || 'no-feature')"
                  [routerLink]="getItemUrl(item)"
                  class="menu-item workspace-item"
                  [ngClass]="{'menu-item__selected': item.selected && !isFolderSelected,
                              'menu-item__uncollapsed': !isMenuCollapsed || isMobileView,
                              'menu-item__collapsed': isMenuCollapsed && !isMobileView}"
                  [disabled]="item.disabled"
                  [matTooltip]="isMenuCollapsed && level === 0 ? item.title : ''"
                  matTooltipPosition="right"
                  (click)="handleClick(item.title)">
                <span *ngIf="!isMenuCollapsed || isMobileView" [ngStyle]="{'padding-left.px': level * 15}">
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success" style="margin-left: 15px;">{{ item.count }}</span>
                </span>
                <i *ngIf="item.icon"
                   [ngClass]="[item.icon, 'icon']"
                   ></i>
                  <!-- [ngStyle]="{'color': item.selected && !isFolderSelected ? 'white' : 'rgba(255, 255, 255, 0.4)'}" -->
              </li>

              <!-- submenu item with recursive template -->
              <li *ngIf="item.children" class="workspace-item">
                <span *ngIf="!isMenuCollapsed || isMobileView" [ngStyle]="{'padding-left.px': level * 15}">
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success" style="margin-left: 15px;">{{ item.count }}</span>
                </span>
                <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
                <ul>
                  <ng-container *ngTemplateOutlet="menuTpl; context: {$implicit: item.children, level: level + 1}"></ng-container>
                </ul>
              </li>
            </ng-container>

          </ng-container>

          <ng-container *ngIf="!isEventEditorOpen">
            <li [ngClass]="{'menu-item': !isMenuCollapsed && !isMobileView,
                'menu-item__uncollapsed': !isMenuCollapsed || isMobileView,
                            'menu-item__collapsed': isMenuCollapsed && !isMobileView}">
              <span *ngIf="!isMenuCollapsed || isMobileView" [ngStyle]="{'padding-left.px': level * 15}">
                <span class="title">App color</span>
              </span>
              <label class="switch">
                <span class="sun"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="#ffd43b"><circle r="5" cy="12" cx="12"></circle><path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path></g></svg></span>
                <span class="moon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path></svg></span>
                <input type="checkbox" class="input" (change)="onChangeTheme()" [checked]="!isDefaultTheme" [disabled]="isSwitchDisabled">
                <span class="slider"></span>
              </label>
              <!-- <mat-slide-toggle #darkModeSwitch (change)="onChangeTheme()" [checked]="isDefaultTheme" [disabled]="isSwitchDisabled">
              </mat-slide-toggle> -->
            </li>
          </ng-container>

          <ng-container *ngIf="isEventEditorOpen">
              <playlist-folder [isMenuCollapsed]="isMenuCollapsed" [isMobileView]="isMobileView" [playlistLimits]="playlistLimits" (isFolderButtonSected)="manageFolderSelection($event)"></playlist-folder>
          </ng-container>

        </ng-template>
      </ul>
    </perfect-scrollbar>
    <div *ngIf="!isMobileView"
          class="aside-trigger"
          [ngClass]="{'dark-trigger': menuColor === 'dark'}"
          [ngStyle]="{ 'width.px': isMenuCollapsed ? 80 : leftMenuWidth }"
          (click)="onCollapse()">
      <mat-icon>{{isMenuCollapsed ? 'chevron_right' : 'chevron_left'}}</mat-icon>
    </div>
  </div>
</aside>
