import { Component, OnDestroy } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { slideFadeinUp, slideFadeinRight, zoomFadein, fadein } from '../router-animations'
import { Subscription } from 'rxjs'
import { SettingsState } from 'src/app/services/app-state/models/settings.state'
import { AppStateService } from 'src/app/services/app-state'

@Component({
  selector: 'layout-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein],
})
export class LayoutAuthComponent implements OnDestroy {
  logo: String
  isGrayTopbar: Boolean
  isCardShadow: Boolean
  isSquaredBorders: Boolean
  isBorderless: Boolean
  authPagesColor: String
  routerAnimation: String
  settings$: Subscription
  settings: SettingsState

  constructor(private appStateService: AppStateService) {
    this.settings$ = this.appStateService.getSettingsState().subscribe(settings => {
      this.settings = settings
      this.logo = settings.logo
      this.isGrayTopbar = settings.isGrayTopbar
      this.isCardShadow = settings.isCardShadow
      this.isSquaredBorders = settings.isSquaredBorders
      this.isBorderless = settings.isBorderless
      this.authPagesColor = settings.authPagesColor
      this.routerAnimation = settings.routerAnimation
      this.routerAnimation = settings.routerAnimation
    })
  }

  routeAnimation(outlet: RouterOutlet, animation: string) {
    if (animation === this.routerAnimation) {
      return outlet.isActivated && outlet.activatedRoute.routeConfig.path
    }
  }

  ngOnDestroy(): void {
    if (this.settings$) {
      this.settings$.unsubscribe()
    }
  }
}
