import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router'
import store from 'store'
import { AppStateService } from 'src/app/services/app-state'

@Injectable({
  providedIn: 'root',
})
class AuthGuardService {
  authorized: boolean

  constructor(public router: Router, private appStateService: AppStateService) {
    this.appStateService.getUserState().subscribe(user => {
      this.authorized = user && user.authorized
    })
  }

  private tokenExpired(token: string) {
    if (!token) return true

    try {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp
      return Math.floor(new Date().getTime() / 1000) >= expiry
    } catch (e) {
      return true
    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let expired = this.tokenExpired(store.get('accessToken'))

    if (this.authorized || !expired) {
      return true
    }

    this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } })
    return false
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean => {
  return inject(AuthGuardService).canActivate(next, state)
}
