<section class="section-padding__sides">
  <header class="dialog-header" cdkFocusInitial>
    <h1 mat-dialog-title class="base-font">{{isMenuCollapsed ? 'Create new folder' : 'Folder settings'}}</h1>
    <mat-divider></mat-divider>
  </header>

  <section *ngIf="!isMenuCollapsed && playlistsFolder">
    <form [formGroup]="playlistFolderSettingsForm">
      <mat-form-field style="width: 100%;" class="base-font">
        <mat-label>Folder name</mat-label>
        <input matInput formControlName="playlistFolderTitle" autocomplete="off">
        <mat-error
          *ngIf="playlistFolderSettingsForm.get('playlistFolderTitle').hasError('required') && playlistFolderSettingsForm.touched">
          Folder name is required
        </mat-error>
        <mat-error *ngIf="playlistFolderSettingsForm.get('playlistFolderTitle').hasError('duplicateName')">
          This folder name already exists
        </mat-error>
      </mat-form-field>
    </form>
    <div>
      <span>Delete folder</span>
      <button mat-icon-button type="button" (click)="onDeleteFolder()">
        <mat-icon fontSet="fal" fontIcon="fa-trash-alt" style="font-size: 1.13rem;"
          [ngStyle]="{'color': isFolderDeleted ? '#f5222e' : 'rgba(0,0,0,.54)'}">
        </mat-icon>
      </button>
    </div>
  </section>

  <section *ngIf="isMenuCollapsed || !playlistsFolder">
    <mat-form-field style="width: 100%;" class="base-font">
      <mat-label>Type in folder name</mat-label>
      <input matInput [(ngModel)]="newFolderTitle" (change)="onCheckTitleDuplicate(newFolderTitle)" autocomplete="off">
    </mat-form-field>
    <mat-error *ngIf="isDuplicated" style="margin-top: -2%; font-size: 75%;"> This folder name already exists
    </mat-error>
  </section>


  <mat-dialog-actions align="end" class="button-holder">
    <button mat-stroked-button class="base-font cancel-btn" (click)="onCancelFolderSettings()">Cancel</button>
    <button mat-raised-button class="base-font apply-btn"
            [disabled]="(!isFolderDeleted && (playlistsFolder && playlistFolderSettingsForm?.invalid && playlistFolderSettingsForm?.touched
                || playlistsFolder && playlistFolderSettingsForm?.untouched
                || playlistsFolder && playlistFolderSettingsForm.get('playlistFolderTitle').hasError('duplicateName')))
                || !playlistsFolder && !newFolderTitle
                || !playlistsFolder && isDuplicated" (click)="onApplyFolderChanges()">Apply
    </button>
  </mat-dialog-actions>

</section>
