import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

export interface FeatureConfig {
  [key: string]: boolean
}

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  config: FeatureConfig = null

  constructor() {}

  loadConfig(): Promise<any> {
    return of(environment.features)
      .pipe(tap(data => (this.config = data)))
      .toPromise()
  }

  isFeatureEnabled(feature: string): boolean {
    if (this.config && this.config.hasOwnProperty(feature)) {
      return this.config[`${feature}`]
    }
    return true
  }
}
