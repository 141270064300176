import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

export interface Content {
  key: string
  publicUri: string
  filename: string
  publicThumbUri: string
  compressedLogoKey: string
  publicUriCompressed: string
}

export type UploadFileContent = Pick<Content, 'key' | 'publicUri' | 'filename'>

interface ContentToCache {
  files: { fileId: string; filename: string }[]
}

export interface CacheContent {
  files: { fileId: string; filename: string; cdnKey: string; cdnUrl: string }[]
}

export interface YouTubeItems {
  items: ItemVideoData[]
  nextPageToken: string
  etag: string
  regionCode: string
  pageInfo: PageInfo
}

export interface PageInfo {
  resultsPerPage: number
  totalResults: number
}

export interface YouTubeSearchItems {
  kind: string
  etag: string
  nextPageToken: string
  regionCode: string
  pageInfo: YoutubePageInfo
  items: YoutubeSearchItem[]
}

export interface YoutubePageInfo {
  totalResults: number
  resultsPerPage: number
}

export interface YoutubeSearchItem {
  kind: string
  etag: string
  id: YoutubeSearchItemId
}

export interface YoutubeSearchItemId {
  kind: string
  videoId: string
}

export interface ItemVideoData {
  id: ItemVideoDataId
  snippet: SnippetDetails
  contentDetails: ContentDetails
}
export interface ItemVideoDataId {
  kind: string
  videoId: string
}

export interface ContentDetails {
  duration: string
}

export interface SnippetDetails {
  title: string
  description: string
  thumbnails: Thumbnails
}
export interface Thumbnails {
  default: ThumbnailDetails
  medium: ThumbnailDetails
  standard: ThumbnailDetails
  maxres: ThumbnailDetails
}
export interface ThumbnailDetails {
  url: string
  width: number
  height: number
}

@Injectable()
export class ContentService {
  constructor(private http: HttpClient) {}

  uploadFile(eventId: string, content: FormData): Observable<Content> {
    return this.http.post<Content>(`${environment.apiBaseUrl}/content/${eventId}/upload`, content)
  }

  uploadInworldModelFile(eventId: string, content: FormData): Observable<UploadFileContent> {
    return this.http.post<UploadFileContent>(
      `${environment.apiBaseUrl}/content/${eventId}/upload`,
      content,
    )
  }

  upload(eventId: string, filename: string, content: Buffer): Observable<Content> {
    return this.http.post<Content>(
      `${environment.apiBaseUrl}/content/${eventId}/?filename=${encodeURIComponent(filename)}`,
      content,
    )
  }

  importFromDrive(
    file: {
      filename: string
      fileId: string
      auth: string
      ar?: number
      width?: number
      height?: number
      rotation?: number
      thumbnailLink?: string
    },
    eventId: string,
  ): Observable<Content> {
    return this.http.post<Content>(`${environment.apiBaseUrl}/content/${eventId}/import`, {
      source: 'gdrive',
      ...file,
    })
  }

  importFromOneDrive(
    file: {
      fileName: string
      fileUrl: string
      thumbUrl: string
      width: number
      height: number
      userId: string
    },
    eventId: string,
  ): Observable<Content> {
    return this.http.post<Content>(`${environment.apiBaseUrl}/content/onedrive/${eventId}/import`, {
      ...file,
    })
  }

  uploadToCache(contentToCache: ContentToCache, eventId: string): Observable<CacheContent> {
    return this.http.post<CacheContent>(`${environment.apiBaseUrl}/content/${eventId}/cache`, {
      //source: 'gdrive', //todo: pass source
      ...contentToCache,
    })
  }

  getYouTubeVideoData(videoId: string): Observable<YouTubeItems> {
    const options = {
      params: {
        id: videoId,
        part: 'contentDetails, snippet',
        key: environment.YOUTUBE_API_KEY,
        fields: 'items(id, contentDetails(duration), snippet(title, description, thumbnails))',
      },
    }
    return this.http.get<YouTubeItems>(environment.YOUTUBE_API_URL, { ...options })
  }

  searchYouTubeVideo(keyword: string, nextPageToken?: string): Observable<YouTubeItems> {
    let params
    if (nextPageToken) {
      params = {
        q: keyword,
        part: 'snippet',
        key: environment.YOUTUBE_API_KEY,
        type: 'video',
        maxResults: '50',
        pageToken: nextPageToken,
      }
    } else {
      params = {
        q: keyword,
        part: 'snippet',
        key: environment.YOUTUBE_API_KEY,
        type: 'video',
        maxResults: '50',
      }
    }
    return this.http.get<YouTubeItems>(environment.YOUTUBE_API_SEARCH_URL, {
      params: { ...params },
    })
  }
}
