import { Injectable } from '@angular/core'
import { Media } from 'src/app/models/playlist-stream.model'
import { Playlist } from 'src/app/models/playlist.model'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root',
})
export class PlaylistCardsCopyingService {
  private savedCurrentPlaylist: Playlist
  private selectionHint: string = 'Select all'
  private selectedCards: Media[] = []
  private chosenPlaylistsStorage: { id?: string; isChosen?: boolean; length?: number }[] = []
  public selectAllBtnClicked = false
  private commonCards: Media[]

  constructor(private snackBar: MatSnackBar) {}

  addSelectedCard(newCard: Media) {
    this.selectedCards.push(newCard)

    if (this.savedCurrentPlaylist.media.length > this.selectedCards.length) {
      this.selectAllBtnClicked = false
      this.selectionHint = 'Select All'
    } else if (
      this.savedCurrentPlaylist.media.length <= this.selectedCards.length &&
      this.savedCurrentPlaylist.media.every(mediaItem =>
        this.selectedCards.some(card => card.suid === mediaItem.suid),
      )
    ) {
      this.markPlaylistAsFullySelected(this.savedCurrentPlaylist)
    }
  }

  addAllCards(allCardSelected: Media[]) {
    if (allCardSelected.some(media => media.kind === 'liveStream')) {
      const liveStreamOmittedCards = allCardSelected.filter(media => media.kind !== 'liveStream')
      this.snackBar.open("doWow-live content can't be copied to another playlist", 'Ok')

      if (liveStreamOmittedCards.length > 0) {
        for (let i = 0; i < liveStreamOmittedCards.length; i++) {
          if (this.unicityCheck(liveStreamOmittedCards[i])) {
            this.selectedCards.push(liveStreamOmittedCards[i])
          }
        }
        this.detectCommonCards()
        this.markPlaylistAsFullySelected(this.savedCurrentPlaylist)
      } else if (liveStreamOmittedCards.length <= 0) {
        this.selectAllBtnClicked = false
        this.selectionHint = 'Select all'
      }
    } else {
      for (let i = 0; i < allCardSelected.length; i++) {
        if (this.unicityCheck(allCardSelected[i])) {
          this.selectedCards.push(allCardSelected[i])
        }
      }
      this.detectCommonCards()
      this.markPlaylistAsFullySelected(this.savedCurrentPlaylist)
    }
  }

  unselectAllCards() {
    this.selectedCards = []
    this.chosenPlaylistsStorage = []
    this.selectAllBtnClicked = false
    this.selectionHint = 'Select all'
    this.commonCards = []
  }

  unselectCard(cardToDelete: Media) {
    this.selectedCards = this.selectedCards.filter(
      item => item.suid !== cardToDelete.suid || item.status !== cardToDelete.status,
    )
    this.handlePlaylistChoice(this.savedCurrentPlaylist)
    this.selectAllBtnClicked = false
    this.selectionHint = 'Select All'
  }

  unicityCheck(cardToCheck: Media): boolean {
    return this.selectedCards.length === 0 ||
      !this.selectedCards.some(card => card.suid === cardToCheck.suid)
      ? true
      : false
  }

  getSelectedCardsData(): Media[] {
    return this.selectedCards
  }

  getSelectionHint(): string {
    return this.selectionHint
  }

  detectCommonCards() {
    this.commonCards = this.savedCurrentPlaylist.media
      .map((media: Media) => this.selectedCards.filter(card => card.suid === media.suid))
      .reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
  }

  handlePlaylistChoice(currentPlaylist: Playlist): boolean {
    this.savedCurrentPlaylist = currentPlaylist
    this.checkPlaylistLength()
    this.detectCommonCards()

    if (this.chosenPlaylistsStorage.length <= 0) {
      this.chosenPlaylistsStorage.push({
        id: currentPlaylist._id,
        isChosen: false,
        length: currentPlaylist.media.length,
      })
      this.checkPlaylistLength()

      return false
    } else if (!this.checkPlaylistAvailability(currentPlaylist)) {
      this.selectionHint = 'Select all'
      this.chosenPlaylistsStorage.push({
        id: currentPlaylist._id,
        isChosen: false,
        length: this.checkPlaylistLength(),
      })
      this.checkPlaylistLength()

      return false
    } else if (
      this.commonCards.length >= currentPlaylist.media.length &&
      this.commonCards.length > 0
    ) {
      this.markPlaylistAsFullySelected(currentPlaylist)

      return false
    } else if (
      this.chosenPlaylistsStorage.some(
        playlist => playlist.id === currentPlaylist._id && playlist.isChosen !== true,
      )
    ) {
      this.selectionHint = 'Select all'
      this.checkPlaylistLength()

      return true
    } else if (
      this.chosenPlaylistsStorage.some(
        playlist => playlist.id === currentPlaylist._id && playlist.isChosen === true,
      )
    ) {
      this.chosenPlaylistsStorage.find(playlist => {
        if (playlist.id === currentPlaylist._id) {
          playlist.isChosen = false
        }
      })

      return false
    }

    return false
  }

  checkPlaylistAvailability(currentPlaylist: Playlist): boolean {
    return this.chosenPlaylistsStorage.some(playlist => playlist.id === currentPlaylist._id)
      ? true //if there is already a playlist with such an id
      : false
  }

  markPlaylistAsFullySelected(currentPlaylist: Playlist) {
    this.chosenPlaylistsStorage.find(playlist => {
      if (playlist.id === currentPlaylist._id) {
        playlist.isChosen = true
        playlist.length = this.checkPlaylistLength()
      }
    })
    this.selectAllBtnClicked = true
    this.selectionHint = 'Unselect all'
  }

  checkPlaylistLength() {
    let length: number
    this.chosenPlaylistsStorage.find(playlist => {
      if (
        playlist.id === this.savedCurrentPlaylist._id &&
        playlist.length !== this.savedCurrentPlaylist.media.length
      ) {
        playlist.length = this.savedCurrentPlaylist.media.length
        length = playlist.length
      } else {
        length = playlist.length
      }
    })

    return length
  }
}
