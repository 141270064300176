import { enableProdMode, ViewEncapsulation } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { browserTracingIntegration, init } from '@sentry/angular-ivy'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
  init({
    dsn: 'https://dbadcd730d0c40108923e2342ec383f5@o513179.ingest.sentry.io/5614568',
    integrations: [browserTracingIntegration()],
    tracePropagationTargets: [window.location.origin],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

if (environment.production) {
  enableProdMode()
}

const bootstrap = () => {
  return platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      defaultEncapsulation: ViewEncapsulation.Emulated,
      preserveWhitespaces: false,
    })
    .then(res => {
      if ((<any>window).appBootstrap) {
        ;(<any>window).appBootstrap()
      }
      return res
    })
}

bootstrap()
