import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { Subscription } from 'rxjs'
import { slideFadeinUp, slideFadeinRight, zoomFadein, fadein } from '../router-animations'
import { AppStateService } from 'src/app/services/app-state'
import { SettingsState } from 'src/app/services/app-state/models/settings.state'

@Component({
  selector: 'layout-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein],
})
export class LayoutMainComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  isContentMaxWidth: Boolean
  isAppMaxWidth: Boolean
  isGrayBackground: Boolean
  isSquaredBorders: Boolean
  isCardShadow: Boolean
  isBorderless: Boolean
  menuLayoutType: string
  isMobileView: Boolean
  isMobileMenuOpen: Boolean
  routerAnimation: string
  isMenuCollapsed: Boolean
  leftMenuWidth: Number
  isTopbarFixed: Boolean
  isGrayTopbar: Boolean

  touchStartPrev: Number = 0
  touchStartLocked: Boolean = false

  settings$: Subscription
  settings: SettingsState

  constructor(private cdr: ChangeDetectorRef, private appStateService: AppStateService) {
    this.settings$ = this.appStateService.getSettingsState().subscribe(settings => {
      this.settings = settings
      this.isContentMaxWidth = settings.isContentMaxWidth
      this.isAppMaxWidth = settings.isAppMaxWidth
      this.isGrayBackground = settings.isGrayBackground
      this.isSquaredBorders = settings.isSquaredBorders
      this.isCardShadow = settings.isCardShadow
      this.isBorderless = settings.isBorderless
      this.menuLayoutType = settings.menuLayoutType
      this.isMobileView = settings.isMobileView
      this.isMobileMenuOpen = settings.isMobileMenuOpen
      this.routerAnimation = settings.routerAnimation
      this.isMenuCollapsed = settings.isMenuCollapsed
      this.leftMenuWidth = settings.leftMenuWidth
      this.isTopbarFixed = settings.isTopbarFixed
      this.isGrayTopbar = settings.isGrayTopbar
    })
  }

  ngOnInit() {
    this.bindMobileSlide()
  }

  ngOnDestroy(): void {
    if (this.settings$) {
      this.settings$.unsubscribe()
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges()
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges()
  }

  onCollapse(value: any) {
    this.appStateService.setSettingsState({ ...this.settings, isMenuCollapsed: value })
  }

  toggleCollapsed() {
    this.appStateService.setSettingsState({
      ...this.settings,
      isMenuCollapsed: !this.isMenuCollapsed,
    })
  }

  toggleMobileMenu() {
    this.appStateService.setSettingsState({
      ...this.settings,
      isMobileMenuOpen: !this.isMobileMenuOpen,
    })
  }

  bindMobileSlide() {
    // mobile menu touch slide opener
    const unify = e => {
      return e.changedTouches ? e.changedTouches[0] : e
    }
    document.addEventListener(
      'touchstart',
      e => {
        const x = unify(e).clientX
        this.touchStartPrev = x
        this.touchStartLocked = x > 70 ? true : false
      },
      { passive: false },
    )
    document.addEventListener(
      'touchmove',
      e => {
        const x = unify(e).clientX
        const prev = this.touchStartPrev
        if (x - <any>prev > 50 && !this.touchStartLocked) {
          this.toggleMobileMenu()
          this.touchStartLocked = true
        }
      },
      { passive: false },
    )
  }

  routeAnimation(outlet: RouterOutlet, animation: string) {
    if (animation === this.routerAnimation) {
      return outlet.isActivated && outlet.activatedRoute.routeConfig.path
    }
  }
}
