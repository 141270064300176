import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments/environment'
import { TwitterApiModel } from 'src/app/models/tweet.model'
import { TwitterApiModelV2 } from 'src/app/models/tweet.v2.model'
import { query } from '@angular/animations'
import { catchError } from 'rxjs/operators'

@Injectable()
export class TwitterService {
  constructor(private http: HttpClient) {}

  search(query: string): Observable<TwitterApiModel> {
    return this.http.get<TwitterApiModel>(
      `${environment.apiBaseUrl}/twitter?q=${encodeURIComponent(query)}`,
    )
  }

  loadNextTweets(query: string): Observable<TwitterApiModel> {
    if (!query || query.length < 3) {
      return of(null)
    }
    return this.http.get<TwitterApiModel>(`${environment.apiBaseUrl}/twitter${query}`)
  }

  searchV2(query: string, userId: string): Observable<TwitterApiModelV2> {
    if (!query || !userId || query.length < 2) {
      return of(null)
    }
    return this.http.get<TwitterApiModelV2>(
      `${environment.apiBaseUrl}/twitter/v2?q=${encodeURIComponent(
        query,
      )}&userId=${encodeURIComponent(userId)}`,
    )
  }

  loadNextTweetsV2(query: string, token: string, userId: string): Observable<TwitterApiModelV2> {
    if (!query || !userId || query.length < 2 || !token || token.length < 2) {
      return of(null)
    }
    return this.http.get<TwitterApiModelV2>(
      `${environment.apiBaseUrl}/twitter/v2?q=${encodeURIComponent(query)}&pt=${encodeURIComponent(
        token,
      )}&userId=${encodeURIComponent(userId)}`,
    )
  }
}
