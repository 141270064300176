import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'
import { HeaderService } from 'src/app/services/header/header.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  @Input() isMenuCollapsed: boolean
  isWallEditorOpen: boolean

  constructor(
    private router: Router,
    public headerService: HeaderService,
    private cdr: ChangeDetectorRef,
  ) {
    this.router.events
      // .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isWallEditorOpen = router.url.includes('edit')
        this.cdr.detectChanges()
      })
  }

  ngAfterViewInit() {
    this.cdr.detectChanges()
  }
}
