import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http'
import { Router } from '@angular/router'
import store from 'store'
import { EMPTY, Observable } from 'rxjs'
import { tap, switchMap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { MatSnackBar } from '@angular/material/snack-bar'
import { JwtHelperService } from '@auth0/angular-jwt'
import { LoginResult, AuthService } from '../services/auth'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private jwtHelper = new JwtHelperService()
  tokenIsRefreshing = false
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = store.get('accessToken')
    const isAllowedRequest =
      request.url.includes('api/auth/') ||
      request.url.includes('users/recaptcha') ||
      request.url.startsWith('https://www.google.com/recaptcha')

    if (!isAllowedRequest && !token) {
      this.snackBar.open('No token found. Please re-login', 'Ok', {
        duration: 3000,
      })
      this.router.navigate(['/auth/login'])
      return EMPTY
    }

    if (token && this.authService.isTokenExpired()) {
      this.snackBar.open('Your session is timed out. Please re-login again', 'Ok', {
        duration: 3000,
      })
      store.remove('accessToken')
      this.router.navigate(['/auth/login'])
      return EMPTY
    }

    if (token && !this.tokenIsRefreshing) {
      const expirationDate = this.jwtHelper.getTokenExpirationDate(token)
      const currentDate = new Date()
      const timeDifference =
        Math.abs(currentDate.getTime() - expirationDate.getTime()) / (1000 * 60)
      if (timeDifference < 7) {
        console.log('Refreshing token....')
        this.tokenIsRefreshing = true
        return this.http
          .post<LoginResult>(`${environment.apiBaseUrl}/auth/refresh`, { observe: 'response' })
          .pipe(
            tap(response => {
              if (response.auth) {
                store.set('accessToken', response.token)
              }
            }),
            switchMap(() => {
              this.tokenIsRefreshing = false
              const token = store.get('accessToken')
              return this.updateRequestWithToken(request, next, token)
            }),
          )
      }
    }

    return this.updateRequestWithToken(request, next, token)
  }

  updateRequestWithToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: string,
  ): Observable<HttpEvent<any>> {
    return next.handle(addToken(request, token)).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            console.log('ERR', err)
            if (err.error.name !== 'TokenExpiredError') {
              return
            }
            this.snackBar.open('Your session is timed out. Please re-login again', 'Ok', {
              duration: 3000,
            })
            this.router.navigate(['/auth/login'])
          }
        },
      ),
    )
  }

  // private tokenExpired(token: string) {
  //   const expiry = JSON.parse(atob(token.split('.')[1])).exp
  //   return Math.floor(new Date().getTime() / 1000) >= expiry
  // }
}

export function addToken<T>(req: HttpRequest<T>, token: string) {
  if (
    token &&
    req.url !== environment.YOUTUBE_API_URL &&
    req.url !== environment.YOUTUBE_API_SEARCH_URL
  ) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
  return req
}
