import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import {
  CheckoutSessionResponse,
  CreateCustomerPortal,
  CreatePortalSessionResponse,
  Price,
  Product,
  StripeCustomer,
  UpdateCustomerDto,
  UserSubscription,
  UserSubscriptionsResponse,
} from 'src/app/models/stripe-payments.model'
import { environment } from 'src/environments/environment'

@Injectable()
export class StripePaymentsService {
  private errorMessage: string = 'Unknow error'
  constructor(private http: HttpClient) {}

  getProducts(): Observable<Product[]> {
    return this.http
      .get<Product[]>(`${environment.apiBaseUrl}/payments/products`)
      .pipe(catchError(this.handleError))
  }

  getPricesByProductId(productId: string): Observable<Price[]> {
    return this.http
      .get<Product[]>(`${environment.apiBaseUrl}/payments/prices/${productId}`)
      .pipe(catchError(this.handleError))
  }

  getUserSubscriptions(userEmail: string): Observable<UserSubscriptionsResponse> {
    return this.http
      .get(`${environment.apiBaseUrl}/payments/subscriptions/email/${userEmail}`)
      .pipe(catchError(this.handleError))
  }

  updateUserMetadata(body: UpdateCustomerDto): Observable<StripeCustomer> {
    return this.http
      .patch(`${environment.apiBaseUrl}/payments/customer`, body)
      .pipe(catchError(this.handleError))
  }

  cancelSubscription(subscriptionId: string): Observable<UserSubscription> {
    const body = {
      subscription: subscriptionId,
      immediatelly: false,
    }
    return this.http
      .patch(`${environment.apiBaseUrl}/payments/subscription/cancel`, body)
      .pipe(catchError(this.handleError))
  }

  revertSubscriptionCancelation(subscriptionId: string): Observable<UserSubscription> {
    const body = {
      subscription: subscriptionId,
      // immediatelly: false
    }
    return this.http
      .patch(`${environment.apiBaseUrl}/payments/subscription/reactivate`, body)
      .pipe(catchError(this.handleError))
  }

  createCheckoutSession(priceId: string, customerId: string): Observable<CheckoutSessionResponse> {
    const body = {
      // customer_email: userEmail,
      customer: customerId,
      success_url: environment.STRIPE_PAYMENTS.successUrl,
      cancel_url: environment.STRIPE_PAYMENTS.cancelUrl,
      line_items: [{ price: priceId, quantity: 1 }],
      mode: 'subscription',
    }
    return this.http
      .post(`${environment.apiBaseUrl}/payments/checkoutsession`, body)
      .pipe(catchError(this.handleError))
  }

  createCustomerPortalSession(
    input: Omit<CreateCustomerPortal, 'return_url'>,
  ): Observable<CreatePortalSessionResponse> {
    const body: CreateCustomerPortal = {
      ...input,
      return_url: environment.STRIPE_PAYMENTS.successUrl,
    }
    return this.http
      .post(`${environment.apiBaseUrl}/payments/customerportal`, body)
      .pipe(catchError(this.handleError.bind(this)))
  }

  setErrorMessage(message: string): void {
    this.errorMessage = message ?? this.errorMessage
  }

  getErrorMessage(): string {
    return this.errorMessage
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    const {
      error: { description, code, error: e },
    } = error
    this.setErrorMessage(description ?? e ?? this.errorMessage)
    return of(null)
  }
}
