import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, catchError, Observable, Subscription, throwError } from 'rxjs'
import { IProfile, OneDriveUserProfile } from 'src/app/models/profile'
import { environment } from 'src/environments/environment'
import { AppStateService } from '../app-state'
import { AppUserState } from '../app-state/models/user.state'
import { IntegrationStatus } from 'src/app/pages/settings/app-integrations/app-integrations.component'
import {
  IOneDriveCreateFolderResponse,
  IOneDriveFileItemDetail,
  IOneDriveItemsListResponse,
  IOneDriveSharedItemResponse,
  OneDriveCreateFolderInput,
  OneDriveItemUploadResponse,
} from 'src/app/models/one-drive.models'

export interface HttpApiResponse {
  code: number
  message: string
}

@Injectable()
export class OneDriveService {
  private authCode: string = null
  integrationStatus: IntegrationStatus = 'Empty'
  user: IProfile
  $errorMessage = new BehaviorSubject<string>('No message available')
  currentUser: AppUserState
  currentUser$: Subscription

  constructor(private http: HttpClient, private appStateService: AppStateService) {
    this.currentUser$ = this.appStateService.getUserState().subscribe(user => {
      if (user) {
        this.currentUser = user
        if (this.currentUser.profile.integrations?.onedrive?.hasOwnProperty('access_token')) {
          this.integrationStatus = 'Ready'
        }
      }
    })
  }

  async openOAuthLink(): Promise<void> {
    const scope = 'user.read files.readwrite.all offline_access'
    // const scope = 'wl.basic onedrive.readwrite'
    const link = `${environment.ONEDRIVE_OAUTH_AUTHORIZE_LINK}?
      client_id=${environment.ONEDRIVE_APPLICATION_ID}&
      scope=${scope}&
      response_type=code&
      redirect_uri=${environment.ONEDRIVE_REDIRECT_URI}`
    window.location.href = link
  }

  async openLogoutLink(): Promise<void> {
    const link = `${environment.ONEDRIVE_LOGOUT_LINK}?post_logout_redirect_uri=${environment.ONEDRIVE_REDIRECT_URI}`
    window.location.href = link
  }

  getRootFolders(userId: string): Observable<IOneDriveItemsListResponse> {
    return this.http
      .get<IOneDriveItemsListResponse>(`${environment.apiBaseUrl}/onedrive/items/user/${userId}`)
      .pipe(catchError(this.handleError.bind(this)))
  }

  listFolderItems(
    userId: string,
    driveId: string,
    itemId: string,
  ): Observable<IOneDriveItemsListResponse> {
    return this.http
      .get<IOneDriveItemsListResponse>(
        `${environment.apiBaseUrl}/onedrive/drive/${driveId}/item/${itemId}/children/user/${userId}`,
      )
      .pipe(catchError(this.handleError.bind(this)))
  }

  // downloadItem(itemId: string): Observable<unknown> {
  //   return this.http
  //     .get<HttpApiResponse>(`${environment.apiBaseUrl}/onedrive/drive/${driveId}/item/${itemId}/children/user/${userId}`)
  //     .pipe(catchError(this.handleError.bind(this)))
  // }

  getAccessToken(): Observable<HttpApiResponse> {
    if (!this.currentUser.profile._id) {
      this.appStateService.refreshAppState()
    }
    const body = {
      authCode: this.authCode,
      userId: this.currentUser.profile._id || '',
    }
    return this.http
      .post<HttpApiResponse>(`${environment.apiBaseUrl}/onedrive/gettoken`, body)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getOneDriveUserProfile(): Observable<OneDriveUserProfile> {
    return this.http
      .get<OneDriveUserProfile>(
        `${environment.apiBaseUrl}/onedrive/user/${this.currentUser.profile._id}`,
      )
      .pipe(catchError(this.handleError.bind(this)))
  }

  getSharedItems(): Observable<IOneDriveSharedItemResponse> {
    return this.http
      .get<IOneDriveSharedItemResponse>(
        `${environment.apiBaseUrl}/onedrive/shared/user/${this.currentUser.profile._id}`,
      )
      .pipe(catchError(this.handleError.bind(this)))
  }

  searchItems(driveId: string, query: string): Observable<IOneDriveItemsListResponse> {
    return this.http
      .get<IOneDriveItemsListResponse>(
        `${environment.apiBaseUrl}/onedrive/drive/${driveId}/user/${this.currentUser.profile._id}/search?param=${query}`,
      )
      .pipe(catchError(this.handleError.bind(this)))
  }

  createFolder(input: OneDriveCreateFolderInput): Observable<IOneDriveCreateFolderResponse> {
    const body = {
      userId: this.currentUser.profile._id,
      folderName: input.folderName,
      parentFolderId: input.parentFolderId,
      isRoot: input.isRoot,
    }
    return this.http
      .post<IOneDriveCreateFolderResponse>(`${environment.apiBaseUrl}/onedrive/folder`, body)
      .pipe(catchError(this.handleError.bind(this)))
  }

  uploadFileToOneDrive(
    parentId: string,
    content: FormData,
  ): Observable<OneDriveItemUploadResponse> {
    return this.http
      .post<OneDriveItemUploadResponse>(
        `${environment.apiBaseUrl}/onedrive/parent/${parentId}/user/${this.currentUser.profile._id}/upload`,
        content,
      )
      .pipe(catchError(this.handleError.bind(this)))
  }

  getIntegrationStatus(): IntegrationStatus {
    return this.integrationStatus
  }

  setIntegrationStatus(status: IntegrationStatus): void {
    this.integrationStatus = status
  }

  setErrorText(errorText: string): void {
    this.$errorMessage.next(errorText)
  }

  getAuthCode(): string {
    return this.authCode
  }

  setAuthCode(code: string): void {
    this.authCode = code
  }

  handleError(error: HttpErrorResponse) {
    const x = error.error.description ?? 'No message available'
    this.setErrorText(x)
    if (error.status === 500) {
      console.error('Server error: ', error.error)
    } else {
      console.error(`Error code: ${error.status}, message: `, error.error)
    }
    return throwError(() => new Error(`${error}`))
  }
}
