import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { StreamKind } from 'src/app/models/playlist-stream.model'
import { Playlist } from 'src/app/models/playlist.model'

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private buttonClickSource = new Subject<void>()
  private settingsClickSource = new Subject<void>()
  private userHintButtonClick = new Subject<boolean>()
  private signOutHandlerClick = new Subject<string>()
  private playlistTitle: string
  private playlistsStreamKind: StreamKind
  isAddingSource: boolean = false
  selectedTabIndex: number = 0
  backButtonClick$ = this.buttonClickSource.asObservable()
  settingsButtonClick$ = this.settingsClickSource.asObservable()
  userHintButtonClick$ = this.userHintButtonClick.asObservable()
  signOutHandlerClick$ = this.signOutHandlerClick.asObservable()
  private userHintButtonStatus: boolean = false
  private isSignedIn: boolean = false

  emitBackButtonClick(): void {
    this.buttonClickSource.next()
  }

  emitSettingsButtonClick(): void {
    this.settingsClickSource.next()
  }

  emitUserHintButtonClick(userHintState: boolean): void {
    this.userHintButtonClick.next(userHintState)
  }

  emitSignOutHandlerClick(currentStreamKind: string) {
    this.signOutHandlerClick.next(currentStreamKind)
  }

  setPlaylist(playlist: Playlist) {
    this.playlistTitle = playlist.title
  }

  get playlistName() {
    return this.playlistTitle
  }

  setCurrentStreamKind(playlistStreamKind: StreamKind, isAddingSource: boolean) {
    this.playlistsStreamKind = playlistStreamKind
    this.isAddingSource = isAddingSource

    this.playlistStreamKind || this.isAddingSource
      ? (this.selectedTabIndex = 1)
      : (this.selectedTabIndex = 0)

    // console.log('stream kind -', this.playlistsStreamKind)
  }

  get playlistStreamKind() {
    return this.playlistsStreamKind
  }

  setUserHintButtonStatus(status: boolean) {
    this.userHintButtonStatus = status
  }

  get shouldShowUserHintButton() {
    return this.userHintButtonStatus
  }

  setSignedInState(state: boolean) {
    this.isSignedIn = state
  }

  get isUserSignedIn() {
    return this.isSignedIn
  }
}
