import { Component, OnInit, Inject, OnDestroy } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { IPlaylistFolder } from 'src/app/models/playlist.model'

@Component({
  selector: 'app-folder-settings-dialog',
  templateUrl: './folder-settings-dialog.component.html',
  styleUrls: ['./folder-settings-dialog.component.scss'],
})
export class FolderSettingsDialogComponent implements OnInit, OnDestroy {
  playlistFolderSettingsForm: FormGroup

  playlistsFolder: IPlaylistFolder<string>
  existingFolderNames: string[]
  newFolderTitle: string
  isMenuCollapsed: boolean
  isDuplicated: boolean = false

  folderChanges: {
    newFolderTitle?: string
    updatedFolder: IPlaylistFolder<string> | undefined
    isFolderDeleted: boolean
  }
  isFolderDeleted: boolean = false

  constructor(
    private dialogRef: MatDialogRef<FolderSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      folder: IPlaylistFolder<string> | undefined
      existingFolderNames: string[]
      isMenuCollapsed: boolean
    },
    private formBuilder: FormBuilder,
  ) {
    this.playlistsFolder = this.data.folder
    this.existingFolderNames = this.data.existingFolderNames
    this.isMenuCollapsed = this.data.isMenuCollapsed
  }

  ngOnInit(): void {
    if (this.playlistsFolder) {
      // if we edit existing folder
      this.playlistFolderSettingsForm = this.formBuilder.group({
        playlistFolderTitle: [
          this.playlistsFolder.name,
          [Validators.required, this.validateFolderName.bind(this)],
        ],
      })
      this.newFolderTitle = this.playlistsFolder.name
    }
  }

  private validateFolderName(control: AbstractControl) {
    const folderName = control.value
    if (!folderName) {
      return { required: true }
    } else if (this.existingFolderNames.includes(folderName)) {
      return { duplicateName: true }
    } else {
      this.newFolderTitle = folderName
      return null
    }
  }

  onCheckTitleDuplicate(title: string) {
    this.existingFolderNames.includes(title)
      ? (this.isDuplicated = true)
      : (this.isDuplicated = false)
  }

  onDeleteFolder() {
    this.isFolderDeleted = !this.isFolderDeleted
  }

  onApplyFolderChanges() {
    if (this.isFolderDeleted) {
      this.folderChanges = {
        updatedFolder: this.playlistsFolder,
        isFolderDeleted: true,
      }
      this.dialogRef.close(this.folderChanges)
    } else if (!this.isMenuCollapsed) {
      this.folderChanges = {
        newFolderTitle: this.playlistFolderSettingsForm.value.playlistFolderTitle
          ? this.playlistFolderSettingsForm.value.playlistFolderTitle
          : '',
        updatedFolder: this.playlistsFolder,
        isFolderDeleted: false,
      }
      this.dialogRef.close(this.folderChanges)
    } else {
      this.folderChanges = {
        newFolderTitle: this.newFolderTitle,
        updatedFolder: undefined,
        isFolderDeleted: false,
      }
      this.dialogRef.close(this.folderChanges)
    }
  }

  onCancelFolderSettings() {
    this.folderChanges = {
      newFolderTitle: '',
      updatedFolder: this.playlistsFolder,
      isFolderDeleted: false,
    }
    this.dialogRef.close(this.folderChanges)
  }

  ngOnDestroy() {
    if (this.playlistsFolder) {
      this.playlistFolderSettingsForm.reset()
    }
  }
}
