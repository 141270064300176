import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import dayjs from 'dayjs'
import { environment } from 'src/environments/environment'
import { Event, CreateEventDto } from 'src/app/models/event.model'

@Injectable()
export class EventService {
  constructor(private http: HttpClient) {}

  createEvent(payload: CreateEventDto): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/event`, {
      ...payload,
    })
  }

  getEvents(): Observable<Event[]> {
    return this.http.get<Event[]>(`${environment.apiBaseUrl}/event`)
  }

  getSingleEvent(id: string): Observable<Event> {
    return this.http.get<Event>(`${environment.apiBaseUrl}/event/${id}`)
  }

  updateEvent(id: string, payload: CreateEventDto): Observable<Event> {
    return this.http.put<Event>(`${environment.apiBaseUrl}/event/${id}`, {
      ...payload,
    })
  }
}
