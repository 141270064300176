<div style="position: fixed; top: 0; left: 0; right: 0; bottom: 0;">
  <div style="display: flex; height: 100vh;">
    <aside *ngIf="menuLayoutType === 'left' && !isMobileView" [mode]="'side'"
      [ngStyle]="{ 'width': isMenuCollapsed ? '80px' : leftMenuWidth + 'px'}">
      <menu-left></menu-left>
    </aside>

    <div *ngIf="!isMobileView" style="flex: 1; display: flex; flex-direction: column;">
      <header>
        <app-header [isMenuCollapsed]="isMenuCollapsed"></app-header>
      </header>

      <main style="flex: 1;">
        <div class="content-container cui__utils__content">
          <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
            [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
            [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')">
            <router-outlet #outlet="outlet"></router-outlet>
          </div>
        </div>
      </main>
    </div>

    <mat-drawer-container *ngIf="isMobileView" [hasBackdrop]="isMobileMenuOpen" (backdropClick)="toggleMobileMenu()"
      style="flex: 1; display: flex; flex-direction: column;">
      <mat-drawer
        style="position: absolute; top: 0; left: 0; display: flex; align-items: center; height: 100vh; width: 256px;"
        class="mobileMenu" [opened]="isMobileMenuOpen" [mode]="'over'" [style.width.px]="leftMenuWidth">
        <menu-left></menu-left>
      </mat-drawer>
      <mat-drawer-content>
        <header>
          <app-header [isMenuCollapsed]="isMenuCollapsed"></app-header>
          <div class="handler" *ngIf="!isMobileMenuOpen" (click)="toggleMobileMenu()">
            <div class="handlerIcon"></div>
          </div>
        </header>

        <main style="flex: 1;">
          <div class="content-container cui__utils__content">
            <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
              [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
              [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')">
              <router-outlet #outlet="outlet"></router-outlet>
            </div>
          </div>
        </main>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
