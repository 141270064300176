<section class="section-padding__main">
  <h4 mat-dialog-title class="base-font">{{ dialogText }}</h4>
  <ng-container *ngIf="data.options?.media?.kind === 'liveStream'">
    <mat-divider></mat-divider>
    <div style="display: flex; align-items: center; height: 3rem; margin-top: .5rem;">
      <mat-checkbox #deleteChannel name="deleteChannel" id="deleteChannel" (change)="onChange(deleteChannel.checked)"
        class="base-font">Delete channel from server permanently</mat-checkbox>
    </div>
  </ng-container>
  <ng-container *ngIf="data.options?.media?.kind === 'zoom' && data.options?.media?.scheduledData">
    <mat-divider></mat-divider>
    <div style="display: flex; align-items: center; height: 3rem; margin-top: .5rem;">
      <mat-checkbox #deleteMeeting name="deleteMeeting" id="deleteMeeting" (change)="onChange(deleteMeeting.checked)"
        class="base-font">Delete meeting from zoom server too</mat-checkbox>
    </div>
  </ng-container>
  <ng-container *ngIf="data?.type === 'stripe_cancel'">
    <mat-divider></mat-divider>
    <div style="margin-top: 1.5rem; padding-left: 1rem;">
      <mat-radio-group aria-label="Select an option" class="column">
        <mat-radio-button *ngFor="let option of stripeOptions" [value]="option" (change)="answerChange($event)">{{ option }}</mat-radio-button>
      </mat-radio-group>
      <mat-form-field *ngIf="selectedStripeUnsubscribeReason === 'Other [write your answer]'" appearance="fill" style="margin-top: 1.5rem; width: 100%;">
        <mat-label>Enter your answer</mat-label>
        <textarea matInput
          type="text"
          placeholder=""
          rows="4"
          [(ngModel)]="unsubscribeReason"
          autocomplete="off"></textarea>
      </mat-form-field>
    </div>
  </ng-container>
  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close class="base-font cancel-btn">Cancel</button>
    <button mat-raised-button class="apply-btn base-font" [disabled]="isSubmitDisabled()" (click)="onSubmit()" cdkFocusInitial>
      {{ buttonText }}
    </button>
  </mat-dialog-actions>
</section>

