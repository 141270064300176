import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface InputPaymentData {
  url: string
  title: string
  text: string
}

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
})
export class PaymentDialogComponent {
  title: string
  text: string

  constructor(@Inject(MAT_DIALOG_DATA) public data: InputPaymentData) {
    this.title = data.title
    this.text = data.text
  }

  goToLink() {
    window.top.location.href = this.data.url
  }

  showCloseBtn(): boolean {
    return true
  }
}
