import store from 'store'

export interface SettingsState {
  authProvider: string // firebase, jwt
  logo: string
  locale: string
  isSidebarOpen: boolean
  isSupportChatOpen: boolean
  isMobileView: boolean
  isMobileMenuOpen: boolean
  isMenuCollapsed: boolean
  menuLayoutType: 'left' | 'top' | 'nomenu'
  routerAnimation: 'none' | 'slideFadeinUp' | 'slideFadeinRight' | 'Fadein' | 'zoomFadein'
  menuColor: 'white' | 'dark' | 'gray' | 'light'
  theme: 'default' | 'dark'
  authPagesColor: 'white' | 'gray' | 'image'
  primaryColor: string
  leftMenuWidth: number
  isMenuUnfixed: boolean
  isMenuShadow: boolean
  isTopbarFixed: boolean
  isGrayTopbar: boolean
  isContentMaxWidth: boolean
  isAppMaxWidth: boolean
  isGrayBackground: boolean
  isCardShadow: boolean
  isSquaredBorders: boolean
  isBorderless: boolean
  isTileViewEvents: boolean
  isTileViewDevices: boolean
  isTabletView: boolean
}

const STORED_SETTINGS = (storedSettings: SettingsState): SettingsState => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings as SettingsState
}

export const initialSettingsState: SettingsState = {
  // default settings, if not exist in localStorage
  ...STORED_SETTINGS({
    authProvider: 'jwt', // firebase, jwt
    logo: 'doWow',
    locale: 'en-US',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    menuLayoutType: 'left', // left, top, nomenu
    routerAnimation: 'slideFadeinUp', // none, slideFadeinUp, slideFadeinRight, Fadein, zoomFadein
    menuColor: 'white', // white, dark, gray
    theme: 'default', // default, dark
    authPagesColor: 'white', // white, gray, image
    primaryColor: '#4b7cf3',
    leftMenuWidth: 256,
    isMenuUnfixed: false,
    isMenuShadow: false,
    isTopbarFixed: false,
    isGrayTopbar: false,
    isContentMaxWidth: false,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isCardShadow: true,
    isSquaredBorders: false,
    isBorderless: false,
    isTileViewEvents: true,
    isTileViewDevices: true,
    isTabletView: false,
  }),
}
