import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable()
export class PaymentService {
  constructor(private http: HttpClient) {}

  getUrl(): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(`${environment.apiBaseUrl}/v2/paywall`)
  }
}
