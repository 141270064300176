import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, LOCALE_ID, importProvidersFrom } from '@angular/core'
import { FormsModule } from '@angular/forms'
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http'
import { TranslateModule } from '@ngx-translate/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthService } from './services/auth'
import { EventService } from './services/event'
import { DeviceService } from './services/device'
import { UserService } from './services/user'
import { TwitterService } from './services/twitter'
import { ZoomService } from './services/zoom'
import { OneDriveService } from './services/onedrive'
import { PlaylistService } from './services/playlist'
import { WallService } from './services/wall'
import { AppStateService } from './services/app-state'
import { TokenInterceptor } from './interceptors/token.interceptor'
import { MaterialModule } from './material.module'

// locale registration
import { registerLocaleData } from '@angular/common'
import { default as localeEn } from '@angular/common/locales/en'
import { ContentService } from './services/content'
import { InstagramService } from './services/instagram'

import { APP_INITIALIZER, ErrorHandler } from '@angular/core'
import { Router } from '@angular/router'
import { createErrorHandler, TraceService } from '@sentry/angular-ivy'
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor'
import { PaymentService } from './services/payment'
import { NftService } from './services/nft'
import { AwsLiveStreamService } from './services/livestream'
import { FeatureService } from './services/feature'
import { StripePaymentsService } from './services/stripe'
import { AiContextService } from './services/ai-api'
import { NgProgressModule } from 'ngx-progressbar'
import { NgProgressHttpModule } from 'ngx-progressbar/http'

const LOCALE_PROVIDERS = [{ provide: LOCALE_ID, useValue: 'en' }]
registerLocaleData(localeEn, 'en')

const featureFactory = (featureFlagsService: FeatureService) => () =>
  featureFlagsService.loadConfig()

const appStateFactory = (appStateService: AppStateService) => () => appStateService.initState()

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    MaterialModule,

    // translate
    TranslateModule.forRoot(),

    // nprogress
    NgProgressModule,
  ],
  providers: [
    // auth services
    AuthService,
    EventService,
    TwitterService,
    ZoomService,
    OneDriveService,
    PlaylistService,
    ContentService,
    PaymentService,
    WallService,
    InstagramService,
    NftService,
    DeviceService,
    AwsLiveStreamService,
    AppStateService,
    StripePaymentsService,
    UserService,
    AiContextService,
    importProvidersFrom(NgProgressHttpModule),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: featureFactory,
      deps: [FeatureService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appStateFactory,
      deps: [AppStateService],
      multi: true,
    },

    // locale providers
    ...LOCALE_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
