import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { FeatureFlagDirective } from 'src/app/directives/feature.directive'

const MODULES = [CommonModule, RouterModule, TranslateModule]

@NgModule({
  imports: [...MODULES],
  declarations: [FeatureFlagDirective],
  exports: [...MODULES, FeatureFlagDirective],
})
export class SharedModule {}
