import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { CreateWallDto, Wall } from 'src/app/models/wall.model'

@Injectable()
export class WallService {
  constructor(private http: HttpClient) {}

  loadSingleWallByEvent(id: string): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/wall?event=${id}`)
  }

  loadSingleWall(id: string): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/wall/${id}`)
  }

  loadWalls(event: string): Observable<Wall[]> {
    return this.http.get<Wall[]>(`${environment.apiBaseUrl}/wall`, {
      params: {
        event,
      },
    })
  }

  createWall(wall: CreateWallDto): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/wall`, wall)
  }

  updateWall(wall: Wall): Observable<any> {
    return this.http.put(`${environment.apiBaseUrl}/wall/${wall._id}`, wall)
  }

  deleteWall(id: string): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/wall/${id}`)
  }
}
