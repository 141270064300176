import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import {
  ActiveStreams,
  IChannelTags,
  ICreateChannel,
  ListedChannel,
} from 'src/app/models/live-stream.model'
import { environment } from 'src/environments/environment'

interface AwsChannelResponse {
  channels: ListedChannel[]
}

export interface StreamStatus {
  streamStatus: string
}

@Injectable()
export class AwsLiveStreamService {
  constructor(private http: HttpClient) {}

  createChannel(params: IChannelTags): Observable<ICreateChannel> {
    const body = { ...params }
    return this.http
      .post<ICreateChannel>(`${environment.apiBaseUrl}/livestream/createchannel`, body)
      .pipe(catchError(this.handleError))
  }

  deleteChannel(channelArn: string): Observable<any> {
    const body = { arn: channelArn }
    return this.http
      .post<ICreateChannel>(`${environment.apiBaseUrl}/livestream/deletechannel`, body)
      .pipe(catchError(this.handleError))
  }

  getChannelList(): Observable<AwsChannelResponse> {
    return this.http
      .get<AwsChannelResponse>(`${environment.apiBaseUrl}/livestream/listchannels`)
      .pipe(catchError(this.handleError))
  }

  getStreamKeys(channelArn: string): Observable<AwsChannelResponse> {
    return this.http
      .get<AwsChannelResponse>(`${environment.apiBaseUrl}/livestream/getstreamkeys`)
      .pipe(catchError(this.handleError))
  }

  getActiveStreams(): Observable<ActiveStreams> {
    return this.http
      .get<ActiveStreams>(`${environment.apiBaseUrl}/livestream/getactivestreams`)
      .pipe(catchError(this.handleError))
  }

  getStreamStatus(channelArn: string): Observable<StreamStatus> {
    const headers = new HttpHeaders({ ignoreProgressBar: '' })
    const options = {
      headers,
      params: { arn: channelArn },
    }
    return this.http
      .get<StreamStatus>(`${environment.apiBaseUrl}/livestream/status`, options)
      .pipe(catchError(this.handleError))
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    return throwError(error)
  }
}
