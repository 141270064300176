import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatRadioChange } from '@angular/material/radio'
import { ConfirmationDialogType } from 'src/app/models/confirmation-dialog.types'

@Component({
  templateUrl: 'deletion-confirmation-dialog.component.html',
  styleUrls: ['./deletion-confirmation-dialog.component.scss'],
})
export class DeletionConfirmationDialogComponent implements OnInit {
  dialogText: string
  buttonText: string
  hasOptions = false
  removeFromServer = false
  options: Record<string, string>
  stripeOptions: Array<string>
  unsubscribeReason: string = ''
  selectedStripeUnsubscribeReason: string = ''

  constructor(
    public dialogRef: MatDialogRef<DeletionConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  ngOnInit() {
    this.dialogText = this.data.dialogText
    this.buttonText = this.data.buttonText

    this.hasOptions = this.data.options ?? false
    this.options = this.data.options ?? null

    this.stripeOptions =
      this.data?.type === ConfirmationDialogType.STRIPE_SUBSCRIPTION_UNSUBSCRIBE
        ? Object.values(this.data?.options)
        : []
  }

  onChange(value: boolean): void {
    this.removeFromServer = value
  }

  onSubmit(): void {
    if (
      this.data.options?.media?.kind === 'liveStream' ||
      (this.data.options?.media?.kind === 'zoom' && this.data.options?.media?.scheduledData)
    ) {
      this.dialogRef.close({ removeFromServer: this.removeFromServer })
    } else if (this.data?.type === ConfirmationDialogType.STRIPE_SUBSCRIPTION_UNSUBSCRIBE) {
      this.dialogRef.close({
        unsubscribeReason:
          this.selectedStripeUnsubscribeReason === 'Other [write your answer]'
            ? this.unsubscribeReason
            : this.selectedStripeUnsubscribeReason,
      })
    } else {
      this.dialogRef.close(true)
    }
  }

  isSubmitDisabled(): boolean {
    if (this.data?.type === ConfirmationDialogType.STRIPE_SUBSCRIPTION_UNSUBSCRIBE) {
      if (this.selectedStripeUnsubscribeReason === 'Other [write your answer]') {
        return this.unsubscribeReason.length === 0
      } else {
        return this.selectedStripeUnsubscribeReason.length === 0
      }
    } else {
      return false
    }
  }

  answerChange(e: MatRadioChange): void {
    this.selectedStripeUnsubscribeReason = e.value
  }
}
