import { Component, HostListener, OnDestroy } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { filesize } from 'humanize'
import { AppUserState, SubscriptionStatus } from 'src/app/services/app-state/models/user.state'
import { Subscription, Subject } from 'rxjs'
import { AppStateService } from 'src/app/services/app-state'
import { Router } from '@angular/router'
import { SettingsState } from 'src/app/services/app-state/models/settings.state'
import { PaymentService } from 'src/app/services/payment'
import { takeUntil } from 'rxjs/operators'
import { StripePaymentsService } from 'src/app/services/stripe'

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnDestroy {
  public username: string
  public subscription: SubscriptionStatus
  public billingButtonBusy = false

  public traffic: number
  public consumedTraffic: string
  public limitTraffic: string
  public storage: number
  public consumedStorage: string
  public limitStorage: string
  isMenuCollapsed: boolean
  public getScreenWidth: number

  currentUser: AppUserState
  currentUser$: Subscription
  settings$: Subscription
  settings: SettingsState

  unsubscribe: Subject<void> = new Subject()

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth
  }

  constructor(
    public dialog: MatDialog,
    private appStateService: AppStateService,
    private router: Router,
  ) {
    this.currentUser$ = this.appStateService
      .getUserState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(user => {
        if (user) {
          this.currentUser = user
          this.username = `${user.profile.firstName} ${user.profile.lastName}`
          this.subscription = user.profile.subscriptionStatus
          this.traffic = user.profile.traffic
            ? (user.profile.traffic.consumed / user.profile.traffic.limit) * 100
            : 0
          this.storage = user.profile.storage
            ? (user.profile.storage.consumed / user.profile.storage.limit) * 100
            : 0
          this.consumedTraffic = filesize(
            user.profile.traffic ? user.profile.traffic.consumed : 0,
            undefined,
            1,
          )
          this.limitTraffic = filesize(
            user.profile.traffic ? user.profile.traffic.limit : 0,
            undefined,
            1,
          )
          this.consumedStorage = filesize(
            user.profile.storage ? user.profile.storage.consumed : 0,
            undefined,
            1,
          )
          this.limitStorage = filesize(
            user.profile.storage ? user.profile.storage.limit : 0,
            undefined,
            1,
          )
        }
      })

    this.settings$ = this.appStateService
      .getSettingsState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(settings => {
        if (settings) {
          this.settings = settings
          this.isMenuCollapsed = settings.isMenuCollapsed
        }
      })
    this.onWindowResize()
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  logout(): void {
    this.appStateService.resetUserProfileData()
    localStorage.removeItem('accessToken')
    this.router.navigate(['/auth/login'])
  }

  upgradeSubscription(): void {
    this.router.navigate(['/settings/application'])
  }

  private TUTORIALS_LINK: string = 'https://dowow.tv/tutorials/'

  viewTutorials() {
    window.open(this.TUTORIALS_LINK, '_blank')
  }
}
