<section>
  <div class="authContainer"
      [ngClass]="{'cui__layout__squaredBorders': isSquaredBorders,
                  'cui__layout__cardsShadow': isCardShadow,
                  'cui__layout__borderless': isBorderless,
                  'white': authPagesColor === 'white',
                  'gray': authPagesColor === 'gray'}"
      [ngStyle]="{backgroundImage: authPagesColor === 'image' ? 'url(assets/images/content/photos/7.jpg)' : ''}">
    <div class="containerInner">
      <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
           [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
           [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')"
           [@fadein]="routeAnimation(outlet, 'fadein')">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
    <footer>
      <ul class="footerNav">
        <li class="list-inline-item">
          <a href="https://dowow.tv/" target="_blank" class="dowow-link">dowow.tv</a>
        </li>
        <li class="list-inline-item">
          <a href="https://dowow.tv/contact-us/" target="_blank">support</a>
        </li>
        <li class="list-inline-item">
          <a href="https://pay.dowow.tv/terms-conditions/" target="_blank">terms of service</a>
        </li>
        <li class="list-inline-item">
          <a href="https://dowow.tv/privacy/" target="_blank">privacy policy</a>
        </li>
        <li class="list-inline-item">
          <a href="https://pay.dowow.tv/" target="_blank">pricing</a>
        </li>
        <li class="list-inline-item">
          <a href="https://dowow.tv/use/" target="_blank" rel="noopener noreferrer">use cases</a>
        </li>
        <li class="list-inline-item">
          <a href="https://dowow.tv/about/" target="_blank" rel="noopener noreferrer">about</a>
        </li>
        <!--          <li class="list-inline-item">-->
        <!--            <a href="https://dowow.tv/contact-us/">Contacts</a>-->
        <!--          </li>-->
      </ul>
      <div class="text-center">
        © 2023 doWow. All rights Reserved.
      </div>
    </footer>
  </div>
</section>
