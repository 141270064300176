<mat-accordion *ngIf="!isMenuCollapsed || isMobileView">
  <mat-expansion-panel hideToggle #panel (closed)="onResetPlaylistFolderForm()">
    <mat-expansion-panel-header>
      <mat-panel-title class="playlists-title__wrapper">
        <li class="playlists-group-title" [ngClass]="{dark: appTheme === 'dark'}">playlists</li>
        <mat-icon [ngStyle]="{'font-size': '1.8rem',
                              'cursor': 'pointer',
                              'color': appTheme === 'dark' ? '#7575a3' : 'rgba(255, 255, 255, 0.4)'}"
                  matTooltip="Create playlists folder"
                  matTooltipPosition="right">
          create_new_folder
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="playlistFolderForm">
      <mat-form-field class="folder-form-field">
        <mat-label>Folder title</mat-label>
        <input matInput class="base-font" formControlName="playlistFolder" #playlistFolder autocomplete="off">
        <button *ngIf="playlistFolder.value.length > 0" type="submit"
          (keydown.enter)="onCreateFolder()"
          (click)="onCreateFolder()"
          class="create-folder"
          matSuffix>
          +
        </button>
        <mat-error *ngIf="playlistFolderForm.get('playlistFolder').hasError('required') && playlistFolderForm.touched">
          Folder name is required
        </mat-error>
        <mat-error *ngIf="playlistFolderForm.get('playlistFolder').hasError('duplicateName')">
          This folder name already exists
        </mat-error>
      </mat-form-field>
    </form>
  </mat-expansion-panel>
</mat-accordion>

<div *ngIf="isMenuCollapsed && !isMobileView"
      class="menu-item__collapsed"
      [ngStyle]="{'background-color': isFolderSelected ? 'rgba(255, 255, 255, 0.1)' : ''}"
      (click)="onFolderSettingsClick(undefined)">
  <mat-icon style="font-size: 1.6rem; cursor: pointer;"
          [ngStyle]="{'color': isFolderSelected ? 'white' : getFolderIconColor()}"
          matTooltip="Create playlists folder"
          matTooltipPosition="right">
          create_new_folder
  </mat-icon>
</div>

<mat-divider style="background: #e4e9f0;" [ngStyle]="{'margin': isMenuCollapsed ? '20px 0' : '0 0 20px 0'}"></mat-divider>

<ul style="position: relative; padding: 0; list-style-type: none;">

  <ng-template #recursiveList let-items let-currentLevel="currentLevel">
    <ul class="folders-and-playlists-wrapper" style="list-style-type: none; padding: 0;">
      <ng-container *ngFor="let playlistItem of items; let i = index; trackBy: trackByFn">
        <div>
          <ng-container *ngIf="isPlaylistFolder(playlistItem); then folderItem else regularItem"></ng-container>

          <ng-template #folderItem>
              <li class="folder-item"
                  [ngClass]="{'folder-item__default': appTheme === 'default', '
                               folder-item__dark': appTheme !== 'default'}"
                  [ngStyle]="{'opacity': isDragging ? 0.5 : 1}">
                <mat-expansion-panel hideToggle
                                     (opened)="folderOpenedState = true" (closed)="folderOpenedState = false"
                                     draggable="true"
                                     (dragstart)="dragStart($event, i, currentLevel, playlistItem)"
                                     (dragend)="dragEnd()"
                                     (dragover)="dragOver($event)"
                                     (drop)="drop($event, i, playlistItem)"
                                     [ngClass]="isMenuCollapsed || !isMenuCollapsed && isMobileView ? 'collapsed' : 'uncollapsed'">

                  <mat-expansion-panel-header [ngStyle]="{'padding': isMenuCollapsed && !isMobileView ? '0' : '0 24px'}">
                    <div class="folder-icon__wrapper"
                         [ngStyle]="{'margin-right': isMenuCollapsed && !isMobileView ? '0' : '10px',
                                     'width': isMenuCollapsed && !isMobileView ? '100%' : ''}"
                         [matTooltip]="isMenuCollapsed ? playlistItem.name : ''"
                         matTooltipPosition="right">
                      <mat-icon style="font-size: 16px; width: 16px; height: 16px;">folder</mat-icon>
                    </div>
                    <span class="folder-item__name" *ngIf="!isMenuCollapsed || isMenuCollapsed && isMobileView">{{ playlistItem.name }}</span>
                  </mat-expansion-panel-header>

                  <button *ngIf="!isMenuCollapsed || isMenuCollapsed && isMobileView"
                          class="folder-item__settings-button slider-btn"
                          matTooltip="Folder settings"
                          matTooltipPosition="below"
                          mat-button
                          (click)="onFolderSettingsClick(playlistItem)">
                    <span class="dw dw-sliders-h-light" style="font-size: 16px; color: rgba(255, 255, 255, 0.55);"></span>
                  </button>
                  <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: playlistItem.items, currentLevel: currentLevel + 1}"></ng-container>
                </mat-expansion-panel>
              </li>
          </ng-template>

          <ng-template #regularItem>
            <li draggable="true"
                (dragstart)="dragStart($event, i, currentLevel, playlistItem)"
                (dragend)="dragEnd()"
                (dragover)="dragOver($event)"
                (drop)="drop($event, i, playlistItem)"
                class="playlist-item"
                [ngStyle]="{'background': isPlaylistSelected(getPlaylist(playlistItem)) ? currentPlaylist?.color : '',
                            'opacity': isDragging ? 0.4 : 1}"
                [ngClass]="{'playlist-item__collapsed': isMenuCollapsed && !isMobileView,
                            'playlist-item__uncollapsed': !isMenuCollapsed || isMobileView}"
                (click)="onPlaylistClick(getPlaylist(playlistItem))">
              <span *ngIf="!isMenuCollapsed || isMenuCollapsed && isMobileView"
                    [ngStyle]="{'color': currentPlaylist?._id === playlistItem ? selectedPlaylistTitleColor(currentPlaylist?.color) : getPlaylistTitleColor()}">
                {{ getPlaylist(playlistItem)?.title }}
              </span>
              <div style="display: flex; justify-content: center;">
                <mat-icon *ngIf="getPlaylistType(getPlaylist(playlistItem)) === 'mixed'"
                          [matTooltip]="getPlaylist(playlistItem)?.title"
                          matTooltipPosition="right"
                          [ngClass]="['icon']"
                          [ngStyle]="{'color': currentPlaylist?._id === playlistItem ? selectedPlaylistTitleColor(currentPlaylist?.color) : getPlaylist(playlistItem)?.color,
                                      'width': 'fit-content',
                                      'height': 'fit-content',
                                      'font-size': currentPlaylist?._id === playlistItem ? '1.8rem' : '1.2rem'}">dynamic_feed
              </mat-icon>
              </div>

              <i *ngIf="getPlaylistType(getPlaylist(playlistItem)) !== 'mixed'"
                  [matTooltip]="getPlaylist(playlistItem)?.title"
                  matTooltipPosition="right"
                  [ngStyle]="{'color': currentPlaylist?._id === playlistItem ? selectedPlaylistTitleColor(currentPlaylist?.color) : getPlaylist(playlistItem)?.color,
                              'font-size': currentPlaylist?._id === playlistItem ? '1.8rem' : '1.13rem'}"
                  [ngClass]="['fe', getPlaylistType(getPlaylist(playlistItem)), 'icon']">
              </i>
            </li>
          </ng-template>
        </div>
      </ng-container>
    </ul>
  </ng-template>

  <div *ngTemplateOutlet="recursiveList; context:{ $implicit: playlistsTree, currentLevel: currentLevel }"></div>

  <button mat-stroked-button class="add-playlist-btn" (click)="addPlaylist()">{{isMenuCollapsed && !isMobileView ? '+' : 'Add Playlist'}}</button>
</ul>
