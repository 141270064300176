import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { environment } from 'src/environments/environment'
import {
  ApiDclNftAssetsModel,
  ApiEthNftAssetsModel,
  ApiSolanaNftAssetsModel,
  OpenSeaCollectionResponse,
  EthNftTokensCollectionModel,
  OpenSeaAssetsResponse,
} from '../../models/nft.model'
import { catchError } from 'rxjs/operators'

@Injectable()
export class NftService {
  constructor(private http: HttpClient) {}

  searchOpenSea(walletId: string, count: number, next?: string): Observable<OpenSeaAssetsResponse> {
    const query = next
      ? `${environment.apiBaseUrl}/nft/assets?walletId=${encodeURIComponent(
          walletId,
        )}&count=${encodeURIComponent(count)}&next=${encodeURIComponent(next)}`
      : `${environment.apiBaseUrl}/nft/assets?walletId=${encodeURIComponent(
          walletId,
        )}&count=${encodeURIComponent(count)}`
    return this.http.get<OpenSeaAssetsResponse>(query).pipe(catchError(this.handleError))
  }

  searchOpenSeaCollection(
    collection: string,
    limit: number,
    next?: string,
  ): Observable<OpenSeaCollectionResponse> {
    const query = next
      ? `${environment.apiBaseUrl}/nft/collection?collection=${encodeURIComponent(
          collection,
        )}&limit=${encodeURIComponent(limit)}&next=${encodeURIComponent(next)}`
      : `${environment.apiBaseUrl}/nft/collection?collection=${encodeURIComponent(
          collection,
        )}&limit=${encodeURIComponent(limit)}`
    return this.http.get<OpenSeaCollectionResponse>(query).pipe(catchError(this.handleError))
  }

  searchEthNft(walletId: string, page: number, count: number): Observable<ApiEthNftAssetsModel> {
    return this.http
      .get<ApiEthNftAssetsModel>(
        `${environment.apiBaseUrl}/nft/eth-assets?walletId=${encodeURIComponent(
          walletId,
        )}&page=${encodeURIComponent(page)}&count=${encodeURIComponent(count)}`,
      )
      .pipe(catchError(this.handleError))
  }

  searchEthNftCollection(
    walletId: string,
    page: number,
    count: number,
  ): Observable<EthNftTokensCollectionModel> {
    return this.http
      .get<EthNftTokensCollectionModel>(
        `${environment.apiBaseUrl}/nft/eth-collection?collectionId=${encodeURIComponent(
          walletId,
        )}&page=${encodeURIComponent(page)}&count=${encodeURIComponent(count)}`,
      )
      .pipe(catchError(this.handleError))
  }

  searchSolanaNft(
    walletId: string,
    page: number,
    count: number,
  ): Observable<ApiSolanaNftAssetsModel> {
    return this.http
      .get<ApiSolanaNftAssetsModel>(
        `${environment.apiBaseUrl}/nft/solana-assets?walletId=${encodeURIComponent(
          walletId,
        )}&page=${encodeURIComponent(page)}&count=${encodeURIComponent(count)}`,
      )
      .pipe(catchError(this.handleError))
  }

  searchDclNftByHashtag(hashtag: string): Observable<ApiDclNftAssetsModel> {
    return this.http
      .get<ApiDclNftAssetsModel>(
        `${environment.apiBaseUrl}/nft/dcl-assets?hashtag=${encodeURIComponent(hashtag)}`,
      )
      .pipe(catchError(this.handleError))
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    return throwError(error)
  }
}
