import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { IProfile } from 'src/app/models/profile'
import { environment } from 'src/environments/environment'

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  updateUserIntegrations(user: Pick<IProfile, 'email' | 'integrations'>): Observable<IProfile> {
    const body = { ...user }
    return this.http.patch<IProfile>(`${environment.apiBaseUrl}/users/integrations`, body)
  }

  updateUserMisc(user: Pick<IProfile, 'email' | 'misc'>): Observable<IProfile> {
    const body = { ...user }
    return this.http.patch<IProfile>(`${environment.apiBaseUrl}/users/misc`, body)
  }
}
