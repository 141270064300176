import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'
import { SettingsState } from 'src/app/services/app-state/models/settings.state'
import { AppStateService } from 'src/app/services/app-state'
import { Wall } from 'src/app/models/wall.model'

@Component({
  selector: 'app-event-breadcrumbs',
  templateUrl: './event-breadcrumbs.component.html',
  styleUrls: ['./event-breadcrumbs.component.scss'],
})
export class EventBreadcrumbsComponent implements OnInit, OnDestroy {
  settings$: Subscription
  event$: Subscription
  wallState$: Subscription
  currentWall$: Subscription
  wallsList$: Subscription

  eventName: string
  eventId: string
  wallIndex: number
  wallSlug: string
  wallName: string
  getScreenWidth: number
  isMenuCollapsed: boolean
  settings: SettingsState
  currentWall: Wall
  wallsList: Wall[]
  currentParentWallIndex: number = 0

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth
  }

  constructor(private router: Router, private appStateService: AppStateService) {
    this.onWindowResize()
    this.settings$ = this.appStateService.getSettingsState().subscribe(settings => {
      this.settings = settings
      this.isMenuCollapsed = settings.isMenuCollapsed
    })
    this.event$ = this.appStateService.getCurrentEventState().subscribe(event => {
      if (event && event._id) {
        this.eventName = event.name
        this.eventId = event._id
      }
    })
    this.wallsList$ = this.appStateService.getWallsState().subscribe(walls => {
      this.wallsList = walls ?? []
    })
    this.currentWall$ = this.appStateService.getCurrentWallState().subscribe(wall => {
      if (wall && wall._id) {
        this.wallIndex = this.wallsList.indexOf(wall)
        this.wallSlug = wall.slug
        const parentWalls = this.wallsList.filter(wall => wall.group?.type !== 'child')
        const index = parentWalls.findIndex(w => w._id === wall._id)
        this.currentParentWallIndex = index !== -1 ? index : this.currentParentWallIndex
        this.wallName = `${this.currentParentWallIndex + 1}${
          this.wallSlug ? ` - ${this.wallSlug}` : ''
        }`
      }
    })
  }

  ngOnInit(): void {}

  handleEventNameClick() {
    this.router.navigate([`/events/${this.eventId}`])
  }

  ngOnDestroy() {
    this.settings$.unsubscribe()
    this.event$.unsubscribe()
    this.wallsList$.unsubscribe()
    this.currentWall$.unsubscribe()
  }
}
