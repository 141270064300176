import { NgModule } from '@angular/core'
import { UserMenuComponent } from './header/user-menu/user-menu.component'
import { HeaderComponent } from './header/header.component'
import { MaterialModule } from '../../material.module'
import { EventBreadcrumbsComponent } from './header/event-breadcrumbs/event-breadcrumbs.component'
import { CommonModule } from '@angular/common'

const COMPONENTS = [HeaderComponent, UserMenuComponent]

@NgModule({
  imports: [MaterialModule, CommonModule],
  declarations: [...COMPONENTS, EventBreadcrumbsComponent],
  exports: [...COMPONENTS],
})
export class CustomLayoutModule {}
