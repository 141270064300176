import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { Injectable } from '@angular/core'

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // if (error.error instanceof ErrorEvent) {
        //   console.log('this is client side error')
        // } else {
        //   console.log('this is client side error', error)
        // }
        return throwError(error)
      }),
    )
  }
}
