import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { filter, catchError, takeUntil } from 'rxjs/operators'
import { MenuService } from 'src/app/services/menu'
import { Event } from '../../../../../models/event.model'
import { Playlist } from 'src/app/models/playlist.model'
import * as _ from 'lodash'
import { StreamKind } from '../../../../../models/playlist-stream.model'
import { SubscriptionStatus } from 'src/app/services/app-state/models/user.state'
import { FeatureService } from 'src/app/services/feature'
import { PlaylistCardsCopyingService } from 'src/app/pages/events/playlist-editor/playlist-cards-copying.service'
import { trigger, transition, style, animate } from '@angular/animations'
import { AppStateService } from 'src/app/services/app-state'
import { AppUserState } from 'src/app/services/app-state/models/user.state'
import { Subscription, of, Subject } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { SettingsState } from 'src/app/services/app-state/models/settings.state'

@Component({
  selector: 'menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class MenuLeftComponent implements OnInit, OnDestroy {
  menuColor: String
  isMenuShadow: Boolean
  isMenuUnfixed: Boolean
  isSidebarOpen: Boolean
  isMobileView: Boolean
  leftMenuWidth: Number
  isMenuCollapsed: Boolean
  isFolderSelected: boolean = false
  logo: String
  menuData: any
  menuDataActivated: any[]
  role: String
  currentPlaylist: Playlist
  isEventEditorOpen: boolean = false
  playlistLimits: number
  subscriptionStatus: SubscriptionStatus
  userLimits: number

  currentUser: AppUserState
  currentEvent: Event
  events: Event[]
  playlists: Playlist[] = []
  appState$: Subscription
  event$: Subscription
  settings$: Subscription
  settings: SettingsState

  isUserProfileLoaded: boolean = false
  isDefaultTheme: boolean
  isSwitchDisabled: boolean = false
  currentTheme: string

  unsubscribe: Subject<void> = new Subject()

  constructor(
    private menuService: MenuService,
    private router: Router,
    private featureFlagService: FeatureService,
    private cardsCopyingService: PlaylistCardsCopyingService,
    private appStateService: AppStateService,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
  ) {
    this.menuService.getMenuData().subscribe(menuData => (this.menuData = menuData))
    this.appState$ = this.appStateService
      .getAppState()
      .pipe(
        takeUntil(this.unsubscribe),
        catchError(error => {
          console.log(error)
          this.snackBar.open('Error getting app data', 'Ok', { duration: 4000 })
          return of([])
        }),
      )
      .subscribe(([user, events]) => {
        if (user && events) {
          this.currentUser = user
          this.isUserProfileLoaded = !user.loading
          this.events = events
          this.subscriptionStatus = user.profile.subscriptionStatus
          this.role = user.profile.role
          this.playlistLimits = user.profile.limits?.playlistsLimit
        }
      })

    this.event$ = this.appStateService
      .getCurrentEventState()
      .pipe(
        takeUntil(this.unsubscribe),
        catchError(error => {
          console.log(error)
          this.snackBar.open('Error getting current event', 'Ok', { duration: 4000 })
          return of(null)
        }),
      )
      .subscribe(event => {
        if (event && event._id) {
          this.currentEvent = event
          this.activateMenu(this.router.url)
        }
      })

    this.settings$ = this.appStateService.getSettingsState().subscribe(settings => {
      this.settings = settings
      this.menuColor = settings.menuColor
      this.isMenuShadow = settings.isMenuShadow
      this.isMenuUnfixed = settings.isMenuUnfixed
      this.isSidebarOpen = settings.isSidebarOpen
      this.isMobileView = settings.isMobileView
      this.leftMenuWidth = settings.leftMenuWidth
      this.isMenuCollapsed = settings.isMenuCollapsed
      this.logo = settings.logo
      this.isDefaultTheme = settings.theme === 'default' ? true : false
      this.currentTheme = settings.theme
    })

    router.url.includes('edit') ? (this.isEventEditorOpen = true) : (this.isEventEditorOpen = false)
  }

  ngOnInit() {
    this.activateMenu(this.router.url)
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        event.url.includes('edit')
          ? (this.isEventEditorOpen = true)
          : (this.isEventEditorOpen = false)
        if (event.url === '/events/dashboard') {
          this.appStateService.setCurrentEventState(null)
        }
        this.activateMenu(event.url ? event.url : null)
      })
  }

  activateMenu(url: any, menuData = this.menuData) {
    menuData = JSON.parse(JSON.stringify(menuData))
    this.menuDataActivated =
      url === '/events/dashboard' ||
      url === '/events/create' ||
      url === '/devices/dashboard' ||
      url.startsWith('/settings')
        ? menuData.general
        : menuData.singleEvent

    if (this.events.length >= this.currentUser.profile.limits?.eventsLimit) {
      const filteredOut = this.menuDataActivated.filter(item => item.title !== 'Create event')
      this.menuDataActivated = filteredOut
    }

    this.menuDataActivated.forEach(item => {
      if (item.url) {
        if (this.currentEvent && item.url.includes('id')) {
          item.url = item.url.replace('id', this.currentEvent._id)
        }
        if (item.url === url) {
          item.selected = true
        }
      }
    })
  }

  isEnabled(item: string): boolean {
    const isDowowUser = this.currentUser.profile.email.endsWith('@dowow.tv')
    return item === 'no-feature'
      ? true
      : this.featureFlagService.isFeatureEnabled(item) && isDowowUser
  }

  manageFolderSelection(state: boolean) {
    this.isFolderSelected = state
  }

  onCollapse() {
    this.appStateService.setSettingsState({
      ...this.settings,
      isMenuCollapsed: !this.isMenuCollapsed,
    })
  }

  getItemUrl(item) {
    if (item.url) {
      return item.url
    }
    return null
  }

  handleClick(type: StreamKind) {
    // console.log('handle click menu-left', type)
    // this.store.dispatch(PlaylistActions.selectCurrentPlaylistType({ payload: type }))
  }

  onChangeTheme() {
    this.isDefaultTheme = !this.isDefaultTheme
    this.appStateService.setSettingsState({
      ...this.settings,
      theme: this.isDefaultTheme ? 'default' : 'dark',
    })
    console.log('Is default theme ->', this.isDefaultTheme, this.currentTheme)

    document.querySelector('html').setAttribute('data-theme', this.currentTheme)
    if (this.currentTheme === 'default') {
      this.appStateService.setSettingsState({ ...this.settings, menuColor: 'light' })
    }
    if (this.currentTheme === 'dark') {
      this.appStateService.setSettingsState({ ...this.settings, menuColor: 'dark' })
    }
  }

  clearCopiedPlaylistCards() {
    this.cardsCopyingService.unselectAllCards()
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}
