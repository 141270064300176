import { Injectable, OnDestroy } from '@angular/core'
import { AppUserState, initialUserState } from './models/user.state'
import { BehaviorSubject, Observable, Subscription, of, forkJoin, combineLatest, NEVER } from 'rxjs'
import { AuthService, RegisterUserDto } from '../auth'
import { IProfile } from 'src/app/models/profile'
import { catchError, tap, switchMap, map, mergeMap, take } from 'rxjs/operators'
import { HttpErrorResponse } from '@angular/common/http'
import { CreatePlaylistDto, Playlist } from 'src/app/models/playlist.model'
import { Event, CreateEventDto } from 'src/app/models/event.model'
import { PlaylistService } from '../playlist'
import { EventService } from '../event'
import { UserService } from '../user'
import { WallService } from '../wall'
import { Wall, CreateWallDto } from 'src/app/models/wall.model'
import { SettingsState, initialSettingsState } from './models/settings.state'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable()
export class AppStateService implements OnDestroy {
  private _userState: BehaviorSubject<AppUserState> = new BehaviorSubject(initialUserState)
  private _playlistsState: BehaviorSubject<Playlist[]> = new BehaviorSubject([])
  private _currentPlaylistState: BehaviorSubject<Playlist> = new BehaviorSubject({} as Playlist)
  private _eventsState: BehaviorSubject<Event[]> = new BehaviorSubject([])
  private _wallsState: BehaviorSubject<Wall[]> = new BehaviorSubject([])
  private _currentEventState: BehaviorSubject<Event> = new BehaviorSubject({} as Event)
  private _currentWallState: BehaviorSubject<Wall> = new BehaviorSubject({} as Wall)
  private _routingState: BehaviorSubject<string> = new BehaviorSubject(null)
  private _settingsState: BehaviorSubject<SettingsState> = new BehaviorSubject(initialSettingsState)

  errorMessage: string = 'No error message'
  appStateSubscripton$: Subscription

  reloadingAppData: boolean = false

  events$ = this.eventsService.getEvents()
  user$ = this.authService.currentAccount()
  appData$ = forkJoin([this.user$, this.events$])

  constructor(
    private authService: AuthService,
    private playlistService: PlaylistService,
    private eventsService: EventService,
    private userService: UserService,
    private wallService: WallService,
    private snackBar: MatSnackBar,
  ) {}

  initState(): void {
    if (
      (this.isInitialUserState() && !this.authService.isTokenExpired()) ||
      (this.reloadingAppData && !this.authService.isTokenExpired())
    ) {
      if (this.reloadingAppData) {
        this.reloadingAppData = false
      }
      this.appStateSubscripton$ = this.appData$
        .pipe(
          tap(([user, events]: [IProfile, Event[]]) => {
            this.updateAppState([user, events])
          }),
          catchError(x => {
            console.log('Error getting init app state data:', x.error)
            return of([])
          }),
        )
        .subscribe()
    }
  }

  updateAppState(appData: [IProfile, Event[]]): Observable<never> {
    const [user, events]: [IProfile, Event[]] = appData
    if (user && events && (user.email || user?.user)) {
      const userState: AppUserState = {
        profile: { ...user },
        authorized: true,
        loading: false,
      }
      this.setUserState(userState)
      this.setEventsState(events)
    }
    return NEVER
  }

  fetchAppData(): Observable<[IProfile, Event[]]> {
    return this.appData$
  }

  refreshAppState(): void {
    this.reloadingAppData = true
    this.initState()
  }

  getAppState(): Observable<[AppUserState, Event[]]> {
    return combineLatest([this.getUserState(), this.getEventsState()])
  }

  getUserState(): Observable<AppUserState> {
    return this._userState.asObservable()
  }

  getEventsState(): Observable<Event[]> {
    return this._eventsState.asObservable()
  }

  getPlaylistsState(): Observable<Playlist[]> {
    return this._playlistsState.asObservable()
  }

  getCurrentPlaylistState(): Observable<Playlist> {
    return this._currentPlaylistState.asObservable()
  }

  getCurrentEventState(): Observable<Event> {
    return this._currentEventState.asObservable()
  }

  getWallsState(): Observable<Wall[]> {
    return this._wallsState.asObservable()
  }

  getCurrentWallState(): Observable<Wall> {
    return this._currentWallState.asObservable()
  }

  getRoutingState(): Observable<string> {
    return this._routingState.asObservable()
  }

  getSettingsState(): Observable<SettingsState> {
    return this._settingsState.asObservable()
  }

  fetchEventById(id: string): void {
    this.eventsService
      .getSingleEvent(id)
      .pipe(
        take(1),
        tap(event => {
          if (event && event._id) {
            this.setCurrentEventState(event)
          }
        }),
        mergeMap(event => {
          const playlists$ = this.fetchPlaylistsByEventId(event._id)
          const walls$ = this.fetchWallsByEventId(event._id)

          return forkJoin({ playlists: playlists$, walls: walls$ })
        }),
        tap(result => {
          if (result.playlists && result.playlists.length) {
            this.setPlaylistsState(result.playlists)
          }

          if (result.walls && result.walls.length) {
            this.setWallsState(result.walls)
            this.setCurrentWallState(result.walls[0])
          }
        }),
        catchError(error => {
          console.log('Error fetching event:', error)
          return of({})
        }),
      )
      .subscribe()
  }

  fetchWallsByEventId(id: string): Observable<Wall[]> {
    return this.wallService.loadWalls(id)
  }

  updateCurrentEvent(id: string, payload: CreateEventDto): void {
    this.eventsService
      .updateEvent(id, payload)
      .pipe(
        take(1),
        tap(event => {
          if (event && event._id) {
            // this._currentEventState.next(event)
            this.setCurrentEventState(event)
          }
        }),
        switchMap(x => this.appData$),
        switchMap(x => this.updateAppState(x)),
        catchError(x => {
          console.log('Error updating event:', x)
          return of({})
        }),
      )
      .subscribe(res => console.log('event updated', res))
  }

  createEvent(payload: CreateEventDto): void {
    this.eventsService
      .createEvent(payload)
      .pipe(
        take(1),
        tap(event => {
          if (event && event._id) {
            this.setCurrentEventState(event)
          }
        }),
        switchMap(x => this.appData$),
        switchMap(x => this.updateAppState(x)),
        catchError(x => {
          console.log('Error creating event:', x)
          return of({})
        }),
      )
      .subscribe()
  }

  updateUser(user: Pick<IProfile, 'email' | 'integrations'>): void {
    this.userService
      .updateUserIntegrations({ email: user.email, integrations: user.integrations })
      .pipe(
        take(1),
        switchMap(x => this.appData$),
        switchMap(x => this.updateAppState(x)),
        catchError(error => {
          console.log('Error updating user integrations: ', error)
          return of(error)
        }),
      )
      .subscribe()
  }

  registerUser(payload: RegisterUserDto): void {
    this.authService
      .register({ ...payload })
      .pipe(
        take(1),
        tap(x => {
          if (x.status === 204) {
            this._routingState.next('login')
          }
        }),
        catchError(error => {
          console.log('Error registering user: ', error)
          return of(error)
        }),
      )
      .subscribe()
  }

  createWall(wall: CreateWallDto): void {
    this.wallService
      .createWall(wall)
      .pipe(
        take(1),
        tap(x => {
          this.setCurrentWallState(x)
        }),
        switchMap(x => this.fetchWallsByEventId(wall.event)),
        tap(x => {
          if (x && x.length) {
            this.setWallsState(x)
          }
        }),
        catchError(error => {
          console.log('Error creating wall: ', error)
          return of(error)
        }),
      )
      .subscribe()
  }

  updateWall(wall: Wall): void {
    this.wallService
      .updateWall(wall)
      .pipe(
        take(1),
        tap(x => {
          this.setCurrentWallState(x)
        }),
        switchMap(x => this.fetchWallsByEventId(wall.event)),
        tap(x => {
          if (x && x.length) {
            this.setWallsState(x)
          }
        }),
        catchError(error => {
          console.log('Error updating wall: ', error)
          return of(error)
        }),
      )
      .subscribe()
  }

  deleteWall(wallId: string, eventId: string): void {
    this.wallService
      .deleteWall(wallId)
      .pipe(
        take(1),
        tap(deletedWallId => {
          if (deletedWallId) {
            console.log(`Wall with ID: ${deletedWallId} was deleted`)
          }
        }),
        switchMap(x => this.fetchWallsByEventId(eventId)),
        tap(x => {
          if (x && x.length) {
            this.setWallsState(x)
            this.setCurrentWallState(x[0])
          }
        }),
        catchError(error => {
          console.log('Error deleting wall: ', error)
          return of(error)
        }),
      )
      .subscribe()
  }

  fetchPlaylistsByEventId(eventId: string): Observable<Playlist[]> {
    return this.playlistService.loadPlaylists(eventId).pipe(
      tap(playlists => {
        if (playlists.length > 0) {
          this.setPlaylistsState(playlists)
        }
      }),
      catchError(error => {
        console.error('Error fetching playlists:', error)
        return of(error)
      }),
    )
  }

  fetchCurrentPlaylistById(id: string): Observable<any> {
    return this.playlistService.loadSinglePlaylist(id).pipe(
      tap(currentPlaylist => {
        if (currentPlaylist && currentPlaylist._id) {
          this.setCurrentPlaylistState(currentPlaylist)
        }
      }),
      catchError(error => {
        console.log('Error fetching current playlist:', error)
        return of({})
      }),
    )
  }

  updateCurrentPlaylist(currentPlaylist: Playlist, eventId: string): Observable<Playlist[]> {
    return this.playlistService.updatePlaylist(currentPlaylist).pipe(
      tap(playlist => {
        if (playlist && playlist._id) {
          this.setCurrentPlaylistState(playlist)
        }
      }),
      switchMap(() => this.fetchPlaylistsByEventId(eventId)),
      tap(playlists => {
        if (playlists && playlists.length) {
          this.setPlaylistsState(playlists)
        }
      }),
      catchError(error => {
        console.log('Error updating playlist: ', error)
        return of(error)
      }),
    )
  }

  createPlaylist(playlist: CreatePlaylistDto, eventId: string): Observable<Playlist[]> {
    return this.playlistService.createPlaylist(playlist).pipe(
      tap(newPlaylist => {
        if (newPlaylist && newPlaylist._id) {
          this.setCurrentPlaylistState(newPlaylist)
        }
      }),
      switchMap(() => this.fetchPlaylistsByEventId(eventId)),
      tap(playlists => {
        if (playlists && playlists.length) {
          this.setPlaylistsState(playlists)
        }
      }),
      catchError(error => {
        console.log('Error creating new playlist:', error)
        return of(error)
      }),
    )
  }

  deletePlaylist(playlistId: string, eventId: string): Observable<any> {
    return this.playlistService.deletePlaylist(playlistId).pipe(
      tap(deletedPlaylistId => {
        if (deletedPlaylistId) {
          console.log(`Playlist with ID: ${playlistId} was deleted`)
        }
      }),
      switchMap(() => this.fetchPlaylistsByEventId(eventId)),
      tap(playlists => {
        if (playlists && playlists.length) {
          this.setPlaylistsState(playlists)
        }
      }),
      catchError(error => {
        console.log('Error deleting playlist ', error)
        return of(error)
      }),
    )
  }

  deletePlaylists(playlistsIdsToDelete: string[], eventId: string): Observable<any> {
    return this.playlistService.deletePlaylists(playlistsIdsToDelete).pipe(
      map(response => {
        if (response.deletedCount === playlistsIdsToDelete.length) {
          if (response.deletedCount === 0) {
            this.snackBar.open('Folders were successfully deleted', 'Ok', { duration: 3000 })
          } else if (response.deletedCount === 1) {
            this.snackBar.open('Playlist was successfully deleted', 'Ok', { duration: 3000 })
          } else {
            this.snackBar.open('Playlists were successfully deleted', 'Ok', { duration: 3000 })
          }

          return response
        }
      }),
      switchMap(() => this.fetchPlaylistsByEventId(eventId)),
      tap(playlists => {
        if (playlists && playlists.length) {
          this.setPlaylistsState(playlists)
        }
      }),
      catchError(error => {
        console.log('Error deleting bunch of playlists', error)
        return of(error)
      }),
    )
  }

  setPlaylistsState(data: Playlist[]) {
    this._playlistsState.next(data)
  }

  setCurrentPlaylistState(data: Playlist): void {
    this._currentPlaylistState.next(data)
  }

  setCurrentEventState(data: Event): void {
    this._currentEventState.next(data)
  }

  setWallsState(data: Wall[]): void {
    this._wallsState.next(data)
  }

  setCurrentWallState(data: Wall): void {
    this._currentWallState.next(data)
  }

  setRoutingState(path: string): void {
    this._routingState.next(path)
  }

  setUserState(data: AppUserState): void {
    this._userState.next(data)
  }

  setEventsState(data: Event[]): void {
    this._eventsState.next(data)
  }

  setSettingsState(settings: SettingsState): void {
    return this._settingsState.next(settings)
  }

  isInitialUserState(): boolean {
    const value = this._userState.getValue()
    return !value.profile._id
  }

  getErrorMessage(): string {
    return this.errorMessage
  }

  resetUserProfileData(): void {
    this._currentEventState.next(null)
    this._currentPlaylistState.next(null)
    this._currentWallState.next(null)
    this._eventsState.next([])
    this._playlistsState.next([])
    this._userState.next(null)
    this._wallsState.next([])
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    this.errorMessage = error.message
    throw new Error(error.message)
  }

  ngOnDestroy(): void {
    if (this.appStateSubscripton$) {
      this.appStateSubscripton$.unsubscribe()
    }
  }
}
