import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { catchError, mergeMap, switchMap, take, tap } from 'rxjs/operators'

export interface ClaimDevice {
  code: string
  description: string
}

export interface IDevice {
  _id: string
  name: string
  resolution: string
  description: string
  secret: string
  __v: number
  owner?: string
  assignedWall?: string
  suspended?: boolean
}

@Injectable()
export class DeviceService {
  private userDevices = new BehaviorSubject<IDevice[]>([])

  constructor(private http: HttpClient) {
    this.loadUserDevices()
      .pipe(take(1))
      .subscribe()
  }

  getUserDevices(): Observable<IDevice[]> {
    return this.userDevices.asObservable()
  }

  claimDevice(code: string, description: string): Observable<IDevice[]> {
    const body = {
      code,
      description,
    }
    return this.http
      .post<IDevice>(`${environment.apiBaseUrl}/devices/claim`, body)
      .pipe(switchMap(x => this.loadUserDevices()))
  }

  getDeviceById(id: string): Observable<IDevice> {
    return this.http.get<IDevice>(`${environment.apiBaseUrl}/devices/${id}`)
  }

  // getDeviceListByUserId(): Observable<IDevice[]> {
  //   return this.http.get<IDevice[]>(`${environment.apiBaseUrl}/devices`).pipe(
  //     tap(devices => {
  //       this.loadUserDevices()
  //     }),
  //   )
  // }

  removeDeviceFromUser(deviceId: string): Observable<IDevice[]> {
    const body = {
      id: deviceId,
    }
    return this.http
      .patch<IDevice>(`${environment.apiBaseUrl}/devices/reject/${deviceId}`, body)
      .pipe(switchMap(x => this.loadUserDevices()))
  }

  suspendDevice(deviceId: string): Observable<IDevice[]> {
    const body = {
      id: deviceId,
    }
    return this.http
      .patch<IDevice>(`${environment.apiBaseUrl}/devices/suspend/${deviceId}`, body)
      .pipe(switchMap(x => this.loadUserDevices()))
  }

  restoreDevice(deviceId: string): Observable<IDevice[]> {
    const body = {
      id: deviceId,
    }
    return this.http
      .patch<IDevice>(`${environment.apiBaseUrl}/devices/restore/${deviceId}`, body)
      .pipe(switchMap(x => this.loadUserDevices()))
  }

  attachWallToDevice(deviceId: string, wallId: string): Observable<IDevice[]> {
    const body = {
      wall: wallId,
    }
    return this.http
      .patch<IDevice>(`${environment.apiBaseUrl}/devices/attachwall/${deviceId}`, body)
      .pipe(switchMap(x => this.loadUserDevices()))
  }

  detachWallFromDevice(deviceId: string, wallId: string): Observable<IDevice[]> {
    const body = {
      wall: wallId,
    }
    return this.http
      .patch<IDevice>(`${environment.apiBaseUrl}/devices/detachwall/${deviceId}`, body)
      .pipe(switchMap(x => this.loadUserDevices()))
  }

  loadUserDevices(): Observable<IDevice[]> {
    return this.http.get<IDevice[]>(`${environment.apiBaseUrl}/devices`).pipe(
      tap(devices => {
        this.userDevices.next(devices)
      }),
      catchError(err => {
        throw 'Error loading user devices: ' + err
      }),
    )
  }
}
