import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { FeatureService } from '../services/feature'

@Directive({
  selector: '[featureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureFlag: string
  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private featureFlagService: FeatureService,
  ) {}

  ngOnInit() {
    const isEnabled = this.featureFlagService.isFeatureEnabled(this.featureFlag)
    if (isEnabled) {
      this.vcr.createEmbeddedView(this.tpl)
    }
  }
}
