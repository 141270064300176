import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { IPlaylistFolder, Playlist, PlaylistId, PlaylistTree } from 'src/app/models/playlist.model'
import * as _ from 'lodash'
import color from 'color'
import { StreamTypesInformation } from 'src/app/pages/events/playlist-editor/stream-types/stream-types-helper'
import { Event } from '../../../../../../models/event.model'
import { MatDialog } from '@angular/material/dialog'
import { FolderSettingsDialogComponent } from 'src/app/components/dialogs/folder-settings-dialog/folder-settings-dialog.component'
import { MatExpansionPanel } from '@angular/material/expansion'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import randomColor from 'randomcolor'
import { DeletionConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/deletion-confirmation-dialog.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { HeaderService } from 'src/app/services/header/header.service'
import { AppStateService } from 'src/app/services/app-state'
import { Subscription, Subject, of } from 'rxjs'
import {
  PaymentDialogComponent,
  InputPaymentData,
} from 'src/app/components/dialogs/payment-dialog/payment-dialog.component'
import { PaymentService } from 'src/app/services/payment'
import { takeUntil, catchError } from 'rxjs/operators'

enum PlaylistLevel {
  ROOT = 0,
  FOLDER = 1,
  SUB_FOLDER = 2,
}

@Component({
  selector: 'playlist-folder',
  templateUrl: './playlist-folder.component.html',
  styleUrls: ['./playlist-folder.component.scss'],
})
export class PlaylistFolderComponent implements OnInit, OnDestroy {
  @Input() isMenuCollapsed: boolean
  @Input() isMobileView: boolean
  @Input() playlistLimits: number

  @Output() isFolderButtonSected = new EventEmitter<boolean>()

  @ViewChild('panel') folderCreationPanel: MatExpansionPanel
  @ViewChild('playlistFolder') playlistFolder: ElementRef

  unsubscribe: Subject<void> = new Subject()
  paymentUrl$: Subscription
  paymentUrl: string = ''

  currentEvent: Event
  currentPlaylist: Playlist
  playlists: Playlist[]
  playlistsTree: PlaylistTree = []

  playlistFolderForm: FormGroup
  existingFolderNames: string[] = []
  folderToDelete: IPlaylistFolder<string>
  folderLevel: number
  currentLevel: number = 0
  draggedItem: 'string' | IPlaylistFolder<string>
  isDragging: boolean = false
  appTheme: string

  isFolderSelected: boolean = false
  event$: Subscription
  playlists$: Subscription
  currentPlaylist$: Subscription
  settings$: Subscription
  playlistCreation$: Subscription
  playlistDeletion$: Subscription
  playlistsBulkDelete$: Subscription

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public headerService: HeaderService,
    private appStateService: AppStateService,
    private paymentService: PaymentService,
  ) {
    this.event$ = this.appStateService
      .getCurrentEventState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(event => {
        if (event && event._id) {
          this.currentEvent = event
          if (this.currentEvent.playlistsTree?.length >= 1) {
            this.playlistsTree = this.currentEvent.playlistsTree
            this.foldersRecursiveSearch(this.playlistsTree, this.existingFolderNames)
          } else {
            this.playlistsTree = []
            this.foldersRecursiveSearch(this.playlistsTree, this.existingFolderNames)
          }
        }
      })

    this.playlists$ = this.appStateService
      .getPlaylistsState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(playlists => {
        if (playlists && playlists.length > 0) {
          this.playlists = playlists
          this.comparePlaylistTreeAndPlaylists(this.playlists, this.playlistsTree)
        }
      })

    this.settings$ = this.appStateService
      .getSettingsState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(settings => {
        this.appTheme = settings.theme
      })

    this.currentPlaylist$ = this.appStateService
      .getCurrentPlaylistState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(state => {
        if (state && Object.keys(state).length !== 0) {
          this.currentPlaylist = state
        }
      })
  }

  ngOnInit() {
    this.playlistFolderForm = this.formBuilder.group({
      playlistFolder: ['', [Validators.required, this.validateFolderName.bind(this)]],
    })
  }

  trackByFn(index: number, item: IPlaylistFolder<PlaylistId> | PlaylistId): any {
    if (typeof item !== 'string') {
      const folder = item as IPlaylistFolder<PlaylistId>

      return folder.name
    } else {
      return item
    }
  }

  comparePlaylistTreeAndPlaylists(playlists: Playlist[], tree: PlaylistTree) {
    const playlistIdsFromTree = this.getAllItemsFromPlaylistTree(tree)
    const playlistsIdsFromEvent: string[] = playlists.map(playlist => playlist._id)

    if (playlistsIdsFromEvent.length !== playlistIdsFromTree.length) {
      if (playlistsIdsFromEvent.length > playlistIdsFromTree.length) {
        tree.push(..._.difference(playlistsIdsFromEvent, playlistIdsFromTree))
      } else {
        tree = this.filterIdsFromPlaylistTree(tree, playlistsIdsFromEvent)
      }
      this.updateEvent(tree, this.currentEvent)
    }

    if (!this.currentPlaylist || !playlistsIdsFromEvent.includes(this.currentPlaylist._id)) {
      this.findSelectedPlaylist()
    }
  }

  getPlaylist(playlistId: string): Playlist | string {
    if (this.playlists && playlistId) {
      const retrievedPlaylist = this.playlists.find(p => p._id === playlistId)

      return retrievedPlaylist ? retrievedPlaylist : ''
    }
  }

  isPlaylistSelected(playlist: Playlist): boolean {
    return _.isEqual(this.currentPlaylist, playlist)
  }

  getPlaylistTitleColor(): string {
    return this.appTheme === 'default' ? 'rgba(255, 255, 255, 0.4)' : '#7575a3'
  }

  getPlaylistType(playlist): string {
    let kind = null
    for (const stream of playlist?.streams ?? []) {
      if (kind != null && kind !== stream.kind) {
        kind = 'mixed'
        break
      }
      kind = stream.kind
    }

    if (kind == null) {
      kind = 'mixed'
    }
    return StreamTypesInformation[kind]?.icon ?? 'mixed'
  }

  selectedPlaylistTitleColor(playlistColor: string) {
    return !playlistColor ? '#fff' : color(playlistColor).isDark() ? '#eee' : '#333'
  }

  onPlaylistClick(playlist: Playlist) {
    if (playlist && (!this.currentPlaylist || this.currentPlaylist._id !== playlist._id)) {
      this.selectPlaylist(playlist)
      this.headerService.setCurrentStreamKind(null, playlist.streams.length ? false : true)
    }
  }

  selectPlaylist(selectedPlaylist: Playlist) {
    this.currentPlaylist = selectedPlaylist
    this.appStateService.setCurrentPlaylistState(this.currentPlaylist)
  }

  isPlaylistFolder(item: string | IPlaylistFolder<string>) {
    // return typeof item !== 'string'
    return item && typeof item === 'object' && 'name' in item && 'items' in item
  }

  dragStart(event: DragEvent, currentIndex: number, currentLevel: number, draggedItem): void {
    event.stopPropagation()
    event.dataTransfer.setData('text/plain', currentIndex.toString())
    event.dataTransfer.dropEffect = 'move'
    this.isDragging = true
    this.draggedItem = draggedItem
    this.folderLevel = currentLevel
    // console.log(`dragStart item`, this.draggedItem, 'from index', currentIndex, 'at level', currentLevel)
  }

  dragEnd() {
    this.isDragging = false
  }

  dragOver(event: DragEvent) {
    event.preventDefault()
  }

  drop(event: DragEvent, targetIndex: number, target: IPlaylistFolder<string> | string) {
    event.preventDefault()
    event.stopPropagation()
    this.isDragging = false
    // console.log('drop target', target, 'of type', typeof target, 'at index', targetIndex)
    const draggedItemIndex = parseInt(event.dataTransfer.getData('text/plain'), 10)

    this.applyNewPlaylistPosition(
      this.playlistsTree,
      this.folderLevel,
      this.draggedItem,
      draggedItemIndex,
      targetIndex,
      target,
    )
  }

  applyNewPlaylistPosition(
    playlistsTree: PlaylistTree,
    folderLevel: number,
    draggedItem: string | IPlaylistFolder<string>,
    draggedItemIndex: number,
    targetIndex?: number,
    target?: string | IPlaylistFolder<string>,
  ): void {
    let shouldUpdate: boolean = false

    switch (folderLevel) {
      case 0:
        playlistsTree.forEach((item, index) => {
          if (
            index === draggedItemIndex &&
            ((typeof draggedItem === 'string' && item === draggedItem) ||
              (typeof draggedItem === 'object' &&
                typeof item === 'object' &&
                item.name === draggedItem.name))
          ) {
            if (typeof target === 'object' && typeof draggedItem === 'string') {
              playlistsTree.splice(draggedItemIndex, 1)
              shouldUpdate = this.addItemToCorrectFolderRecursively(
                playlistsTree,
                draggedItem,
                target,
              )

              if (shouldUpdate === undefined) {
                playlistsTree.splice(draggedItemIndex, 0, draggedItem)
                this.showWarningNotification('Playlist', 'self-drop')
              } else if (shouldUpdate === false) {
                playlistsTree.splice(draggedItemIndex, 0, draggedItem)
                this.showWarningNotification('Playlist', 'nesting')
              }
              // console.log('✅ dropped from root into the folder', shouldUpdate)
            } else if (typeof target === 'string') {
              shouldUpdate = this.dropToTargetFolderByPlaylistId(
                this.playlistsTree,
                target,
                targetIndex,
                draggedItem,
                draggedItemIndex,
                playlistsTree,
              )

              if (shouldUpdate === undefined) {
                this.showWarningNotification(
                  this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                  'self-drop',
                )
              } else if (shouldUpdate === false) {
                this.showWarningNotification(
                  this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                  'nesting',
                )
              }
              // console.log('✅ dropped from root into the folder by string', shouldUpdate)
            } else if (
              typeof item === 'object' &&
              typeof draggedItem === 'object' &&
              typeof target === 'object' &&
              item.name === draggedItem.name &&
              target.name === draggedItem.name &&
              item.name === target.name
            ) {
              // console.log('this must be a self drop of folder in root')
              this.showWarningNotification('Folder', 'self-drop')
            } else if (
              typeof item === 'object' &&
              typeof draggedItem === 'object' &&
              typeof target === 'object' &&
              target.name !== draggedItem.name
            ) {
              // console.log('this must be a nested drop of folder in root')
              this.showWarningNotification(
                this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                'nesting',
              )
            }
          }
        })

        break

      case 1:
        let draggedItemFolder: IPlaylistFolder<string> = this.findDraggedItemFolder(
          draggedItem,
          draggedItemIndex,
          folderLevel,
        )
        // console.log('The folder that was dragged from found', draggedItemFolder)

        playlistsTree.forEach(playlistsItem => {
          if (typeof playlistsItem === 'object' && 'items' in playlistsItem) {
            playlistsItem.items.forEach((subItem, index) => {
              if (
                (playlistsItem.name === draggedItemFolder.name && // if we dragged the playlist exactly from this folder
                  typeof subItem === 'string' &&
                  subItem === draggedItem &&
                  index === draggedItemIndex) ||
                (playlistsItem.name === draggedItemFolder.name && // if we dragged the folder exactly from this folder
                  typeof subItem === 'object' &&
                  typeof draggedItem === 'object' &&
                  subItem.name === draggedItem.name &&
                  index === draggedItemIndex)
              ) {
                if (typeof target === 'object') {
                  playlistsItem.items.splice(draggedItemIndex, 1)
                  shouldUpdate = this.addItemToCorrectFolderRecursively(
                    playlistsTree,
                    draggedItem,
                    target,
                  )
                  if (shouldUpdate === undefined) {
                    playlistsItem.items.splice(draggedItemIndex, 0, draggedItem)
                    this.showWarningNotification(
                      this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                      'self-drop',
                    )
                  } else if (shouldUpdate === false) {
                    playlistsItem.items.splice(draggedItemIndex, 0, draggedItem)
                    this.showWarningNotification(
                      this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                      'nesting',
                    )
                  }
                  // console.log('✅ dropped from folder to folder', shouldUpdate)
                } else {
                  shouldUpdate = this.dropToTargetFolderByPlaylistId(
                    this.playlistsTree,
                    target,
                    targetIndex,
                    draggedItem,
                    draggedItemIndex,
                    playlistsItem.items,
                  )
                  if (shouldUpdate === undefined) {
                    this.showWarningNotification(
                      this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                      'self-drop',
                    )
                  } else if (shouldUpdate === false) {
                    this.showWarningNotification(
                      this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                      'nesting',
                    )
                  }
                  // console.log('✅ dropped from the folder by string', shouldUpdate)
                }
              }
            })
          }
        })

        break

      case 2:
        let draggedItemSubFolder: IPlaylistFolder<string> = this.findDraggedItemFolder(
          draggedItem,
          draggedItemIndex,
          folderLevel,
        )
        // console.log('Subfolder from which dragged found!', draggedItemSubFolder)

        playlistsTree.forEach(playlistsItem => {
          if (typeof playlistsItem === 'object' && 'items' in playlistsItem) {
            playlistsItem.items.forEach(subFolder => {
              if (typeof subFolder === 'object' && 'items' in subFolder) {
                subFolder.items.forEach((subFolderItem, subFolderItemIndex) => {
                  if (
                    subFolder.name === draggedItemSubFolder.name &&
                    typeof subFolderItem === 'string' &&
                    subFolderItem === draggedItem &&
                    subFolderItemIndex === draggedItemIndex
                  ) {
                    if (typeof target === 'object') {
                      subFolder.items.splice(draggedItemIndex, 1)
                      shouldUpdate = this.addItemToCorrectFolderRecursively(
                        playlistsTree,
                        draggedItem,
                        target,
                      )
                      if (shouldUpdate === undefined) {
                        subFolder.items.splice(draggedItemIndex, 0, draggedItem)
                        this.showWarningNotification(
                          this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                          'self-drop',
                        )
                      } else if (shouldUpdate === false) {
                        subFolder.items.splice(draggedItemIndex, 0, draggedItem)
                        this.showWarningNotification(
                          this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                          'nesting',
                        )
                      }
                      // console.log('✅ dropped from a subfolder to a folder', shouldUpdate)
                    } else if (typeof target === 'string') {
                      shouldUpdate = this.dropToTargetFolderByPlaylistId(
                        playlistsTree,
                        target,
                        targetIndex,
                        draggedItem,
                        draggedItemIndex,
                        draggedItemSubFolder.items,
                      )

                      if (shouldUpdate === undefined) {
                        this.showWarningNotification(
                          this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                          'self-drop',
                        )
                      } else if (shouldUpdate === false) {
                        this.showWarningNotification(
                          this.isPlaylistFolder(draggedItem) ? 'Folder' : 'Playlist',
                          'nesting',
                        )
                      }
                      // console.log('✅ dropped from the subfolder by string', shouldUpdate)
                    }
                  }
                })
              }
            })
          }
        })

        break

      default:
        break
    }
    if (shouldUpdate) {
      this.updateEvent(playlistsTree, this.currentEvent)
      shouldUpdate = false
    }
  }

  findDraggedItemFolder(
    draggedItem: IPlaylistFolder<string> | string,
    draggedItemIndex: number,
    folderLevel: number,
  ): IPlaylistFolder<string> {
    // console.log('search for a folder...')
    if (folderLevel === PlaylistLevel.FOLDER) {
      for (const folder of this.playlistsTree) {
        if (typeof folder === 'object' && 'items' in folder) {
          for (const [itemIndex, item] of folder.items.entries()) {
            if (
              !this.isPlaylistFolder(item) &&
              item === draggedItem &&
              itemIndex === draggedItemIndex
            ) {
              return folder
            } else if (
              typeof draggedItem === 'object' &&
              typeof item === 'object' &&
              item.name === draggedItem.name &&
              itemIndex === draggedItemIndex
            ) {
              return folder
            }
          }
        }
      }
    } else if (folderLevel === PlaylistLevel.SUB_FOLDER) {
      for (const folder of this.playlistsTree) {
        if (typeof folder === 'object' && 'items' in folder) {
          for (const subFolder of folder.items) {
            if (typeof subFolder === 'object' && 'items' in subFolder) {
              for (const [subFolderItemIndex, subFolderItem] of subFolder.items.entries()) {
                if (
                  !this.isPlaylistFolder(subFolderItem) &&
                  subFolderItem === draggedItem &&
                  subFolderItemIndex === draggedItemIndex
                ) {
                  return subFolder
                } else if (
                  typeof subFolderItem === 'object' &&
                  typeof draggedItem === 'object' &&
                  subFolderItem.name === draggedItem.name &&
                  subFolderItemIndex === draggedItemIndex
                ) {
                  return subFolder
                }
              }
            }
          }
        }
      }
    }
  }

  findDropTargetFolder(
    targetIndex: number,
    target: string,
  ): IPlaylistFolder<string> | undefined | number {
    for (let i = 0; i < this.playlistsTree.length; i++) {
      if (
        typeof this.playlistsTree[i] === 'string' &&
        i === targetIndex &&
        this.playlistsTree[i] === target
      ) {
        return 0
      } else if (typeof this.playlistsTree[i] === 'object') {
        let folder: IPlaylistFolder<string> = this.playlistsTree[i] as IPlaylistFolder<string>

        for (let j = 0; j < folder.items.length; j++) {
          if (
            typeof folder.items[j] === 'string' &&
            j === targetIndex &&
            folder.items[j] === target
          ) {
            return folder
          } else if (typeof folder.items[j] === 'object') {
            let subFolder: IPlaylistFolder<string> = folder.items[j] as IPlaylistFolder<string>

            for (let k = 0; k < subFolder.items.length; k++) {
              if (
                typeof subFolder.items[k] === 'string' &&
                k === targetIndex &&
                subFolder.items[k] === target
              ) {
                return subFolder
              }
            }
          }
        }
      }
    }

    return undefined
  }

  dropToTargetFolderByPlaylistId(
    playlistsTree: PlaylistTree,
    target: string,
    targetIndex: number,
    draggedItem: IPlaylistFolder<string> | string,
    draggedItemIndex: number,
    draggedFrom: PlaylistTree,
  ): boolean {
    const dropTargetFolder = this.findDropTargetFolder(targetIndex, target)

    if (dropTargetFolder === PlaylistLevel.ROOT) {
      if (
        (typeof draggedItem === 'string' && target !== draggedItem) ||
        typeof draggedItem === 'object'
      ) {
        draggedFrom.splice(draggedItemIndex, 1)
        playlistsTree.splice(targetIndex, 0, draggedItem)

        return true
      }
    } else if (dropTargetFolder) {
      if (
        typeof dropTargetFolder === 'object' &&
        typeof draggedItem === 'string' &&
        dropTargetFolder.items[targetIndex] !== draggedItem
      ) {
        // ||
        //     (typeof draggedItem === 'object' &&
        //       !draggedItem.items.some(item => typeof item === 'object') &&
        //       dropTargetFolder.name !== draggedItem.name))
        draggedFrom.splice(draggedItemIndex, 1)

        return this.addItemToCorrectFolderRecursively(
          playlistsTree,
          draggedItem,
          dropTargetFolder as IPlaylistFolder<string>,
          targetIndex,
        )
      } else if (
        typeof draggedItem === 'object' &&
        draggedItem.items.some(item => typeof item === 'object')
      ) {
        return false
      } else if (typeof draggedItem === 'object' && typeof dropTargetFolder === 'object') {
        return false
      } else {
        return undefined
      }
    }
  }

  addItemToCorrectFolderRecursively(
    playlistsTree: PlaylistTree,
    draggedItem: IPlaylistFolder<string> | string,
    target: IPlaylistFolder<string>,
    targetIndex?: number,
    foldingLevel: number = 1,
  ): boolean {
    let foldingLevelState = foldingLevel

    function addItemRecursive(treeLevel: PlaylistTree, foldingLevel: number): boolean {
      if (
        typeof draggedItem === 'object' &&
        draggedItem.name !== target.name &&
        draggedItem.items.some(item => typeof item === 'object')
      ) {
        return false
      } else if (typeof draggedItem === 'object' && draggedItem.name === target.name) {
        return undefined
      }

      foldingLevelState =
        foldingLevel >= PlaylistLevel.SUB_FOLDER ? foldingLevel : foldingLevelState

      for (let i = 0; i < treeLevel.length; i++) {
        if (typeof treeLevel[i] === 'object') {
          const folder = treeLevel[i] as IPlaylistFolder<string>

          if (folder.name === target.name) {
            if (
              foldingLevel < PlaylistLevel.SUB_FOLDER ||
              (foldingLevel === PlaylistLevel.SUB_FOLDER && typeof draggedItem === 'string')
            ) {
              if (
                targetIndex !== undefined &&
                targetIndex >= 0 &&
                targetIndex <= folder.items.length
              ) {
                folder.items.splice(targetIndex, 0, draggedItem)
              } else {
                folder.items.push(draggedItem)
              }

              return true
            }
          } else if (folder.items && folder.items.length > 0) {
            const added = addItemRecursive(folder.items, foldingLevel + 1)
            if (added) {
              return true
            }
          }
        }
      }
      return foldingLevelState > PlaylistLevel.SUB_FOLDER ? undefined : false
    }

    return addItemRecursive(playlistsTree, foldingLevel)
  }

  showWarningNotification(notificationType: string, notificationReason: string): void {
    if (notificationReason === 'self-drop') {
      this.snackBar.open(
        `Dropping ${notificationType.toLocaleLowerCase()} onto itself has no effect`,
        'Ok',
        {
          duration: 3000,
        },
      )
    } else {
      this.snackBar.open('Folders can only be nested at one level', 'Ok', {
        duration: 3000,
      })
    }
  }

  onFolderSettingsClick(folder: IPlaylistFolder<string> | undefined) {
    if (this.isMenuCollapsed && !folder) {
      this.isFolderSelected = !this.isFolderSelected
      this.isFolderButtonSected.emit(this.isFolderSelected)
    }

    const dialogRef = this.dialog.open(FolderSettingsDialogComponent, {
      maxWidth: '50vw',
      minWidth: '30vw',
      height: 'auto',
      data: {
        folder: folder,
        existingFolderNames: this.existingFolderNames,
        isMenuCollapsed: this.isMenuCollapsed,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result?.isFolderDeleted) {
        this.onDeleteFolder(result.updatedFolder)
      } else if (result?.newFolderTitle && result?.updatedFolder) {
        this.foldersRecursiveSearch(
          this.playlistsTree,
          null,
          result.updatedFolder,
          result.newFolderTitle,
        )
      } else if (result.newFolderTitle) {
        this.isFolderButtonSected.emit(false)
        this.addFolderToTree(result.newFolderTitle)
      } else {
        this.isFolderSelected = !this.isFolderSelected
        this.isFolderButtonSected.emit(this.isFolderSelected)
      }
    })
  }

  onResetPlaylistFolderForm() {
    this.playlistFolderForm.reset()
  }

  foldersRecursiveSearch(
    tree: PlaylistTree,
    folderNames?: string[] | null,
    folder?: IPlaylistFolder<string>,
    newFolderName?: string,
  ) {
    for (const item of tree) {
      if (typeof item === 'object' && 'name' in item) {
        const currentFolder = item as IPlaylistFolder<string>

        if (folderNames) {
          this.saveExistingFolderName(folderNames, currentFolder.name)

          if (currentFolder.items && currentFolder.items.length > 0) {
            this.foldersRecursiveSearch(currentFolder.items, folderNames)
          }
        } else {
          if (currentFolder.name === folder.name) {
            // change the name of the matching folder
            this.changeFolderName(currentFolder, newFolderName)
          } else if (currentFolder.items && currentFolder.items.length > 0) {
            this.foldersRecursiveSearch(currentFolder.items, null, folder, newFolderName)
          }
        }
      }
    }
  }

  saveExistingFolderName(folderNames: string[], newFolderName: string) {
    folderNames.push(newFolderName)
  }

  changeFolderName(currentFolder: IPlaylistFolder<string>, newFolderName: string) {
    this.existingFolderNames = this.existingFolderNames.map(name =>
      name === currentFolder.name ? newFolderName : name,
    )
    currentFolder.name = newFolderName
    this.updateEvent(this.playlistsTree, this.currentEvent)
  }

  private validateFolderName(control: AbstractControl) {
    const folderName = control.value
    if (!folderName) {
      return { required: true }
    }

    if (this.existingFolderNames.includes(folderName)) {
      return { duplicateName: true }
    }

    return null
  }

  randomColorLuminosity() {
    return Math.floor(Math.random() * 10) + 1 <= 5 ? 'bright' : 'light'
  }

  addPlaylist() {
    if (this.playlistLimits && this.playlists.length >= this.playlistLimits) {
      this.paymentUrl$ = this.paymentService
        .getUrl()
        .pipe(
          takeUntil(this.unsubscribe),
          catchError(e => of({ url: '' })),
        )
        .subscribe(urlData => {
          if (urlData) {
            this.paymentUrl = urlData.url
            const dialogData: InputPaymentData = {
              url: this.paymentUrl,
              title: 'Action Required',
              text: `You have reached the maximum number of playlists, ${this.playlistLimits} for your account.\n\nPlease upgrade your account if you would like to add more playlists and features.`,
            }
            const dialogRef = this.dialog.open(PaymentDialogComponent, {
              panelClass: 'payment-dialog',
              width: '30%',
              data: dialogData,
            })
          }
        })
    } else {
      this.playlistCreation$ = this.appStateService
        .createPlaylist(
          {
            event: this.currentEvent._id,
            title: 'Playlist',
            streams: [],
            media: [],
            color: randomColor({
              luminosity: this.randomColorLuminosity(),
              hue: this.currentEvent.colorShade,
            }),
            colorShade: this.currentEvent.colorShade,
          },
          this.currentEvent._id,
        )
        .subscribe()
    }
  }

  makeExtensibleRecursive(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
      return obj
    }

    // If the object is an array, make a copy and apply recursion to each element
    if (Array.isArray(obj)) {
      return obj.map(item => this.makeExtensibleRecursive(item))
    }

    // Create a new object with the same prototype
    const newObj = Object.create(Object.getPrototypeOf(obj))

    // Copy the properties from the original object into the new object
    Object.getOwnPropertyNames(obj).forEach(prop => {
      newObj[prop] = this.makeExtensibleRecursive(obj[prop])
    })

    // Make the new object extensible
    return newObj
  }

  getAllItemsFromPlaylistTree(tree: PlaylistTree): PlaylistId[] {
    const allItems: PlaylistId[] = []

    function traverseTree(tree: PlaylistTree) {
      for (const item of tree) {
        if (typeof item === 'string') {
          allItems.push(item)
        } else if (Array.isArray(item.items)) {
          traverseTree(item.items)
        }
      }
    }
    traverseTree(tree)

    return allItems
  }

  filterIdsFromPlaylistTree(tree: PlaylistTree, validIds: PlaylistId[]): PlaylistTree {
    function filterFolderItems(folderItems: PlaylistTree): PlaylistTree {
      const filteredItems: PlaylistTree = []
      for (const item of folderItems) {
        if (typeof item === 'string') {
          if (validIds.includes(item)) {
            filteredItems.push(item)
          }
        } else {
          filteredItems.push({
            name: item.name,
            items: filterFolderItems(item.items),
          })
        }
      }
      return filteredItems
    }

    const filteredTree = filterFolderItems(tree)

    return filteredTree
  }

  updateEvent(playlistsTree: PlaylistTree, event: Event) {
    if (playlistsTree && event) {
      const e = this.makeExtensibleRecursive({ ...event })
      const plt = this.makeExtensibleRecursive([...playlistsTree])
      const eventToUpdate = {
        ...e,
        playlistsTree: plt,
      }
      this.appStateService.updateCurrentEvent(e._id, eventToUpdate)
    }
  }

  findSelectedPlaylist() {
    if (this.playlists.length > 0) {
      const playlistId = this.playlistsTree.find(playlist =>
        !this.isPlaylistFolder(playlist) ? playlist : '',
      )
      const playlist = this.getPlaylist(playlistId as string)

      playlist ? this.selectPlaylist(playlist as Playlist) : this.selectPlaylist(this.playlists[0])
    }
  }

  ifFolderNameExists(playlistsTree: PlaylistTree, folderName: string): boolean {
    for (const item of playlistsTree) {
      if (typeof item === 'object' && 'name' in item) {
        const folder = item as IPlaylistFolder<string>
        if (folder.name === folderName) {
          return true
        } else if (folder.items && folder.items.length > 0) {
          const isExists = this.ifFolderNameExists(folder.items, folderName)
          if (isExists) {
            return true
          }
        }
      }
    }
    return false
  }

  onCreateFolder() {
    if (this.playlistFolderForm.invalid) {
      return
    }
    const newPlaylistFolderName = this.playlistFolderForm.value.playlistFolder
    this.playlistFolderForm.reset()
    this.folderCreationPanel.close()
    this.addFolderToTree(newPlaylistFolderName)
  }

  addFolderToTree(folderName: string) {
    this.existingFolderNames.push(folderName)
    this.existingFolderNames = [...Array.from(new Set(this.existingFolderNames))]
    this.playlistsTree.splice(0, 0, { name: folderName, items: [] })

    this.updateEvent(this.playlistsTree, this.currentEvent)
  }

  onDeleteFolder(folderToDelete: IPlaylistFolder<string>) {
    if (folderToDelete.items.length > 0) {
      const dialogRef = this.dialog.open(DeletionConfirmationDialogComponent, {
        data: {
          dialogText: 'Are you sure you also want to remove all of the contents inside the folder?',
          buttonText: 'Remove',
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.existingFolderNames = this.existingFolderNames.filter(
            name => name !== folderToDelete.name,
          )
          this.folderToDelete = folderToDelete
          const playlistsIdsToDelete = this.getAllItemsFromPlaylistTree(folderToDelete.items)
          playlistsIdsToDelete.length > 1
            ? (this.playlistsBulkDelete$ = this.appStateService
                .deletePlaylists(playlistsIdsToDelete, this.currentEvent._id)
                .subscribe(res => {
                  this.playlistsTree = this.removeFolderFromTree(
                    this.playlistsTree,
                    folderToDelete.items,
                  )
                  this.updateEvent(this.playlistsTree, this.currentEvent)
                }))
            : (this.playlistDeletion$ = this.appStateService
                .deletePlaylist(playlistsIdsToDelete.toString(), this.currentEvent._id)
                .subscribe(res => {
                  this.playlistsTree = this.removeFolderFromTree(
                    this.playlistsTree,
                    folderToDelete.items,
                  )
                  this.updateEvent(this.playlistsTree, this.currentEvent)
                }))
          if (!this.currentPlaylist) {
            this.findSelectedPlaylist()
          }
        }
      })
    } else {
      this.playlistsTree = this.removeFolderFromTree(this.playlistsTree, folderToDelete.items)
      this.existingFolderNames = this.existingFolderNames.filter(
        name => name !== folderToDelete.name,
      )

      if (!this.currentPlaylist) {
        this.findSelectedPlaylist()
      }

      this.snackBar.open('Folder was successfully deleted', 'Ok', {
        duration: 3000,
      })

      this.updateEvent(this.playlistsTree, this.currentEvent)
    }
  }

  removeFolderFromTree(tree: PlaylistTree, folder: PlaylistTree): PlaylistTree {
    return tree.filter(item => {
      if (typeof item === 'string') {
        return true // Keep non-folder items
      } else {
        if (item.items === folder) {
          return false // Skip the specified folder
        } else {
          item.items = this.removeFolderFromTree(item.items, folder) // Recurse into nested items
          return true // Keep other folders
        }
      }
    })
  }

  getFolderIconColor() {
    return this.appTheme === 'default' ? 'rgba(255, 255, 255, 0.4)' : '#7575a3'
  }

  ngOnDestroy() {
    this.playlistsTree = []
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}
