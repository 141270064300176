import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { CreatePlaylistDto, Playlist } from 'src/app/models/playlist.model'

@Injectable()
export class PlaylistService {
  constructor(private http: HttpClient) {}

  loadSinglePlaylist(id: string): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/playlist/${id}`)
  }

  loadPlaylists(event: string): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/playlist`, {
      params: {
        event,
      },
    })
  }

  createPlaylist(playlist: CreatePlaylistDto): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/playlist`, playlist)
  }

  updatePlaylist(playlist: Playlist): Observable<any> {
    return this.http.put(`${environment.apiBaseUrl}/playlist/${playlist._id}`, playlist)
  }

  deletePlaylist(event: string): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/playlist/${event}`)
  }

  deletePlaylists(playlistsIds: string[]): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/playlist/bulkdelete`, {
      ids: playlistsIds,
    })
  }
}
