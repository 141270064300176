import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, from } from 'rxjs'
import { ApiInstagramModel } from '../../models/instagram.model'

export interface InstaAccount {
  pageId: string
  instaId: string
  accessToken: string
}

const cache: Map<string, ApiInstagramModel> = new Map<string, ApiInstagramModel>()

const hashTagMap: Record<string, string> = {}

@Injectable()
export class InstagramService {
  constructor(private http: HttpClient) {}

  search(
    user_id: string,
    access_token: string,
    query: string,
    mediaEndpoint,
  ): Observable<ApiInstagramModel> {
    if (!query || query.length < 3) {
      return of({ media: [], paging: { next: null } })
    }
    query.replace('#', '')

    const base = {
      user_id,
      access_token,
    }

    const cacheKey = `${query}/${mediaEndpoint}`
    const cashed = cache.get(cacheKey)

    return new Observable(subscriber => {
      subscriber.next({ ...cashed, loading: true } || null)

      return from(
        (async () => {
          if (!hashTagMap.hasOwnProperty(query)) {
            const { data, error } = await this.request(`/ig_hashtag_search`, { ...base, q: query })
            const id = data?.[0]?.id
            if (id) {
              hashTagMap[query] = id
            } else {
              cache.set(cacheKey, { media: [], paging: null, error })
              return { media: [], paging: null, error }
            }
          }

          const { data, paging, error } = await this.request(
            `/${hashTagMap[query]}/${mediaEndpoint}`,
            {
              ...base,
              fields:
                'id,media_type,media_url,caption,permalink,timestamp,children{media_type,media_url}',
            },
          )

          cache.set(cacheKey, { media: data, paging, error })
          return { media: data, paging, error, loading: false }
        })(),
      ).subscribe(subscriber)
    })
  }

  public async fetchAccounts(access_token: string): Promise<InstaAccount[]> {
    const { data } = await this.request('/me/accounts', {
      access_token,
      fields: 'id,access_token,instagram_business_account,name',
    })
    return data
      .map(({ id, access_token, instagram_business_account }) => ({
        pageId: id,
        accessToken: access_token,
        instaId: instagram_business_account?.id,
      }))
      .filter(x => x.instaId)
  }

  loadNext(user_id: string, access_token: string, query: string): Observable<ApiInstagramModel> {
    return from(
      (async () => {
        const { data: media, paging } = await (await fetch(query, {})).json()
        return { media, paging }
      })(),
    )
  }

  private async request(url: string, q: Record<string, string>): Promise<any> {
    const fbApiVersion = '9.0'
    const params = new URLSearchParams(q).toString()
    const response = await fetch(`https://graph.facebook.com/v${fbApiVersion}${url}?${params}`, {})
    if (!response.ok) {
      const details = await response.text()
      const error = JSON.parse(details)?.error
      if (error) {
        return { data: [], paging: null, error: error.error_user_title }
      }
      throw new Error(`FB api error ${response.status} ${response.statusText} ${details}`)
    }

    return response.json()
  }
}
