<section class="section-padding__sides">
  <div class="dialog-header">
    <h4 mat-dialog-title class="base-font">{{ title }}</h4>
    <mat-divider></mat-divider>
  </div>

  <mat-dialog-content>
    <p class="base-font payment-dialog__text">{{ text }}</p>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="action-btns button-holder">
    <button mat-stroked-button mat-dialog-close class="base-font cancel-btn">Cancel</button>
    <button mat-raised-button class="apply-btn base-font payment-dialog__upgrade" (click)="goToLink()">UPGRADE</button>
  </mat-dialog-actions>
</section>
