<div class="user-menu-wrapper">
  <button *ngIf="getScreenWidth > 540 && isMenuCollapsed || !isMenuCollapsed && getScreenWidth >= 1050"
          mat-button
          class="tutorials-button base-font"
          matTooltip="Visit our tutorials center"
          matTooltipPosition="below"
          (click)="viewTutorials()">
    <span class="fas fa-question-circle"></span>
    <span style="font-size: 22px; margin-left: 8px;">HELP</span>
  </button>

  <button mat-button class="user-menu-trigger" [matMenuTriggerFor]="userMenu">
    <mat-icon>account_circle</mat-icon>
    <mat-icon>expand_more</mat-icon>
  </button>

  <mat-menu #userMenu="matMenu" class="user-menu" xPosition="before">
    <h4 class="user-menu__username base-font">{{username}}</h4>
    <hr>
    <div class="button-wrapper" *ngIf="getScreenWidth <= 540|| !isMenuCollapsed && getScreenWidth <= 1050">
      <button mat-button
              class="tutorials-button base-font"
              matTooltip="Visit our tutorials center"
              matTooltipPosition="below"
              (click)="viewTutorials()">
        <span class="fas fa-question-circle" style="font-size: 20px; padding-right: 16px;"></span>
        HELP
      </button>
    </div>

    <hr *ngIf="getScreenWidth <= 540">
    <button mat-menu-item (click)="upgradeSubscription()" [disabled]="billingButtonBusy">
      <mat-icon>paid</mat-icon>
      <span class="user-menu__font-size base-font">My Subscription</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span class="user-menu__font-size base-font">Log out</span>
    </button>
    <hr>
    <div mat-menu-item style="line-height: 24px; margin-bottom: 15px;">
      <div style="display: flex; justify-content: space-between;">
        <span class="user-menu__font-size base-font">Traffic:</span>
      </div>
      <mat-progress-bar style="width: 220px;" mode="determinate" [value]="traffic"></mat-progress-bar>
      <div style="display: flex; color: gray">
        <span class="user-menu__font-size base-font">{{consumedTraffic}} of {{limitTraffic}} used</span>
      </div>
    </div>
    <div mat-menu-item style="line-height: 24px; margin-bottom: 10px;">
      <div style="display: flex; justify-content: space-between;">
        <span class="user-menu__font-size base-font">Storage:</span>
      </div>
      <mat-progress-bar style="width: 220px;" mode="determinate" [value]="storage"></mat-progress-bar>
      <div style="display: flex; color: gray">
        <span class="user-menu__font-size base-font">{{consumedStorage}} of {{limitStorage}} used</span>
      </div>
    </div>
  </mat-menu>

</div>
