import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DowowAiInputContextResponse } from 'src/app/models/dowow-ai.model'
import { environment } from 'src/environments/environment'

@Injectable()
export class AiContextService {
  constructor(private http: HttpClient) {}

  postContext(context: FormData): Observable<DowowAiInputContextResponse> {
    return this.http.post<DowowAiInputContextResponse>(
      `${environment.apiBaseUrl}/contexts/`,
      context,
    )
  }

  updateContext(context: FormData, contextUid: string): Observable<DowowAiInputContextResponse> {
    return this.http.patch<DowowAiInputContextResponse>(
      `${environment.apiBaseUrl}/contexts/${contextUid}`,
      context,
    )
  }

  removeContext(contextUid: string): Observable<unknown> {
    return this.http.delete<unknown>(`${environment.apiBaseUrl}/contexts/${contextUid}`)
  }
}
