import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, throwError, Subscription } from 'rxjs'
import { IProfile, ZoomUserProfile } from 'src/app/models/profile'
import { environment } from 'src/environments/environment'
import { CreateZoomMeeting, ScheduledMeeting } from 'src/app/models/zoom.model'
import { catchError, tap } from 'rxjs/operators'
import { AppStateService } from '../app-state'
import { AppUserState } from '../app-state/models/user.state'
import { IntegrationStatus } from 'src/app/pages/settings/app-integrations/app-integrations.component'

export type ScheduledMeetingListed = Pick<
  ScheduledMeeting,
  'agenda' | 'created_at' | 'duration' | 'host_id' | 'id' | 'join_url' | 'start_time' | 'timezone' | 'topic' | 'type' | 'uuid'
>

export interface MeetingStatus {
  code: number
}

export interface ZakTokenResponse {
  token: string
}

export interface MeetingList {
  page_size: number
  total_records: number
  next_page_token: string
  meetings: ScheduledMeetingListed[]
}

export interface ZoomApiResponse {
  code: number
  message: string
}

@Injectable()
export class ZoomService {
  private authCode: string = null
  integrationStatus: IntegrationStatus = 'Empty'
  user: IProfile
  $errorMessage = new BehaviorSubject<string>('No message available')
  currentUser: AppUserState
  currentUser$: Subscription

  constructor(private http: HttpClient, private appStateService: AppStateService) {
    this.currentUser$ = this.appStateService
      .getUserState()
      .pipe(
        tap(user => {
          if (user) {
            this.currentUser = user
            if (this.currentUser.profile.integrations?.zoom?.hasOwnProperty('access_token')) {
              this.integrationStatus = 'Ready'
            } else if (!this.currentUser.profile.integrations?.zoom) {
              this.integrationStatus = 'Empty'
            }
          }
        }),
      )
      .subscribe()
  }

  async openOAuthLInk(): Promise<void> {
    const link = `
      ${environment.ZOOM_OAUTH_AUTHORIZE_LINK}?response_type=code&client_id=${environment.ZOOM_APP_CLIEND_ID}&redirect_uri=${environment.ZOOM_REDIRECT_URI}`
    window.location.href = link
  }

  getAccessToken(): Observable<ZoomApiResponse> {
    if (!this.currentUser.profile._id) {
      this.appStateService.refreshAppState()
    }
    const body = {
      authCode: this.authCode,
      userId: this.currentUser.profile._id || '',
    }
    return this.http.post<ZoomApiResponse>(`${environment.apiBaseUrl}/zoom/gettoken`, body).pipe(catchError(this.handleError.bind(this)))
  }

  getZoomUserProfile(): Observable<ZoomUserProfile> {
    return this.http.get<ZoomUserProfile>(`${environment.apiBaseUrl}/zoom/user/${this.currentUser.profile._id}`).pipe(catchError(this.handleError.bind(this)))
  }

  getIntegrationStatus(): IntegrationStatus {
    return this.integrationStatus
  }

  setIntegrationStatus(status: IntegrationStatus): void {
    this.integrationStatus = status
  }

  getAuthCode(): string {
    return this.authCode
  }

  setAuthCode(code: string): void {
    this.authCode = code
  }

  resetAuthCode(): void {
    this.authCode = null
  }

  scheduleMeeting(meetingData: CreateZoomMeeting): Observable<ScheduledMeeting | ZoomApiResponse> {
    const body = {
      userId: this.currentUser.profile._id,
      ...meetingData,
    }
    return this.http.post<ScheduledMeeting>(`${environment.apiBaseUrl}/zoom/schedulemeeting`, body).pipe(catchError(this.handleError.bind(this)))
  }

  updateMeeting(meetingData: CreateZoomMeeting & Pick<ScheduledMeeting, 'id'>): Observable<MeetingStatus> {
    const body = {
      id: meetingData.id,
      userId: this.currentUser.profile._id,
      ...meetingData,
    }
    return this.http.put<MeetingStatus>(`${environment.apiBaseUrl}/zoom/updatemeeting`, body).pipe(catchError(this.handleError.bind(this)))
  }

  getMeetingById(meetingId: Pick<ScheduledMeeting, 'id'>): Observable<ScheduledMeeting | MeetingStatus> {
    return this.http
      .get<ScheduledMeeting>(`${environment.apiBaseUrl}/zoom/getmeeting?meetingId=${meetingId}&userId=${this.currentUser.profile._id}`)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getUserScheduledMeetings(): Observable<MeetingList> {
    return this.http
      .get<MeetingList>(`${environment.apiBaseUrl}/zoom/getusermeetings?&userId=${this.currentUser.profile._id}`)
      .pipe(catchError(this.handleError.bind(this)))
  }

  deleteMeetingById(meetingId: Pick<ScheduledMeeting, 'id'>): Observable<MeetingStatus> {
    return this.http
      .delete<MeetingStatus>(`${environment.apiBaseUrl}/zoom/deletemeeting?meetingId=${meetingId}&userId=${this.currentUser.profile._id}`)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getZakToken(): Observable<ZakTokenResponse> {
    return this.http
      .get<ZakTokenResponse>(`${environment.apiBaseUrl}/zoom/user/zak/${this.currentUser.profile._id}`)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getErrorNotification(): Observable<string> {
    return this.$errorMessage.asObservable()
  }

  setErrorText(errorText: string): void {
    this.$errorMessage.next(errorText)
  }

  handleError(error: HttpErrorResponse) {
    const x = error.error.description ?? 'No message available'
    this.setErrorText(x)
    if (error.status === 500) {
      console.error('Server error: ', error.error)
    } else {
      console.error(`Error code: ${error.status}, message: `, error.error)
    }
    return throwError(() => new Error(`${error}`))
  }
}
