import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { catchError } from 'rxjs/operators'
import store from 'store'
import { JwtHelperService } from '@auth0/angular-jwt'
import { IProfile } from '../../models/profile'
import { RecaptchaActionsEnum } from './models/recaptcha.actions'

export interface LoginResult {
  auth: boolean
  token: string
}

export interface RegisterUserDto {
  email: string
  password: string
  firstName: string
  lastName: string
  subscribed: boolean
  tosAgreed: boolean
}

@Injectable()
export class AuthService {
  private tokenChecking: boolean = false
  private jwtHelper = new JwtHelperService()

  constructor(private http: HttpClient) {}

  login(email: string, password: string): Observable<LoginResult> {
    return this.http.post<LoginResult>(`${environment.apiBaseUrl}/auth/login`, { email, password })
  }

  register(user: RegisterUserDto): Observable<any> {
    return this.http.post(
      `${environment.apiBaseUrl}/auth/registration`,
      {
        ...user,
        subscriptionPlan: 'free',
      },
      { observe: 'response' },
    )
  }

  currentAccount(): Observable<IProfile> {
    return this.http.get<IProfile>(`${environment.apiBaseUrl}/profile`)
  }

  logout(): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/auth/logout`)
  }

  resetPassword(email: string): Observable<any> {
    return this.http.post(
      `${environment.apiBaseUrl}/auth/reset`,
      { email },
      { observe: 'response' },
    )
  }

  verifyEmail(token: string): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/auth/verify-email?token=${token}`, {
      observe: 'response',
    })
  }

  changePassword(password): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}/auth/change-password`, { password }, { observe: 'response' })
      .pipe(catchError(this.handleError))
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    return throwError(error)
  }

  isTokenExpired(): boolean {
    const token = localStorage.getItem('accessToken')
    if (token) {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp
      return Math.floor(new Date().getTime() / 1000) >= expiry
    }
    return true
  }

  getRecaptchaAssessment(token: string, action: RecaptchaActionsEnum): Observable<number> {
    const body = {
      token: token,
      recaptchaAction: action,
    }
    const options = {
      headers: {
        'x-api-key': environment.RECAPTCHA_ENDPOINT_API_KEY,
      },
    }
    return this.http
      .post(`${environment.apiBaseUrl}/users/recaptcha`, body, options)
      .pipe(catchError(this.handleError))
  }

  // checkToken() {
  //   if (!this.tokenChecking) {
  //     this.tokenChecking = true
  //     setInterval(() => {
  //       const token = store.get('accessToken')
  //       if (token) {
  //         const expirationDate = this.jwtHelper.getTokenExpirationDate(token)
  //         const currentDate = new Date()
  //         const timeDifference =
  //           Math.abs(currentDate.getTime() - expirationDate.getTime()) / (1000 * 60)
  //         if (timeDifference < 7) {
  //           this.http
  //             .post<LoginResult>(`${environment.apiBaseUrl}/auth/refresh`, null)
  //             .subscribe(result => {
  //               if (result.auth) {
  //                 store.set('accessToken', result.token)
  //               }
  //             })
  //         }
  //       }
  //     }, 300000)
  //   }
  // }
}
